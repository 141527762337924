import React, { useState, useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './styling/slider.css';

import image1 from './assets/slider/1.jpg';
import image2 from './assets/slider/2.jpg';
import image3 from './assets/slider/3.jpg';
import image4 from './assets/slider/4.jpg';
import image5 from './assets/slider/5.jpg';
import image6 from './assets/slider/6.jpg';
import image7 from './assets/slider/7.jpg';
import image8 from './assets/slider/8.jpg';
import image9 from './assets/slider/9.jpg';
import image10 from './assets/slider/10.jpg';
import image11 from './assets/slider/11.jpg';
import image12 from './assets/slider/12.jpg';
import image13 from './assets/slider/13.jpg';
import image14 from './assets/slider/14.jpg';

const ImageSlider = () => {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);

  const slider1 = useRef(null);
  const slider2 = useRef(null);

  const settingsMain = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    lazyLoad: 'ondemand', // Lazy load images on demand
    asNavFor: nav2, // Sync with thumbnail slider
    arrows: false,
  };

  const settingsThumbs = {
    slidesToShow: 4,
    slidesToScroll: 1,
    dots: false,
    centerMode: true,
    focusOnSelect: true,
    asNavFor: nav1, // Sync with main slider
    arrows: false,
  };

  const images = [
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
    image10,
    image11,
    image12,
    image13,
    image14
  ];

  return (
    <div>
      {/* Main Image Slider */}
      <Slider
        {...settingsMain}
        ref={(slider) => setNav1(slider)}
        className="slider-for"
      >
        {images.map((image, index) => (
          <div key={index}>
            <img
              src={image}
              alt={`slide-${index}`}
              style={{ width: '100%', height: '600px', objectFit: 'cover' }}
            />
          </div>
        ))}
      </Slider>

      {/* Thumbnail Slider */}
      <Slider
        {...settingsThumbs}
        ref={(slider) => setNav2(slider)}
        className="slider-nav"
      >
        {images.map((image, index) => (
          <div key={index}>
            <img
              src={image}
              alt={`thumbnail-${index}`}
              style={{
                width: '100%',
                height: '100px',
                objectFit: 'cover',
                cursor: 'pointer',
              }}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ImageSlider;
