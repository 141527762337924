import React, { useEffect, useState } from "react";
import "./styling/pdfList.css";
import { getAllPdfsMetadata, deletePdf, setPdfActive, getPdfData } from "../dataSource/PDFSource";
import { ClipLoader } from 'react-spinners';

const PDFList = ({ dataRefresh, setDataRefresh, formatDate, restaurant }) => {
  const [pdfs, setPdfs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [downloadLoadingId, setDownloadLoadingId] = useState(0);

  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 1200px)").matches
  );

  useEffect(() => {
    fetchPdfsMetadata();
  }, []);

  useEffect(() => {
    if (dataRefresh) {
      fetchPdfsMetadata();
    }
  }, [dataRefresh]);

  const fetchPdfsMetadata = async () => {
    setLoading(true);
    const pdfList = await getAllPdfsMetadata(restaurant);
    if (pdfList) {
      // Sort PDFs by timestamp in descending order
      pdfList.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
      setPdfs(pdfList);
    }
    setLoading(false);
  };

  const handleDelete = async (pdf) => {
    
    const success = await deletePdf(restaurant, pdf.id);
    if (success) {
      fetchPdfsMetadata(); // Refresh the list after deletion
      if(pdf.active){ // Also refresh active pdf if needed
        setDataRefresh(true);
      }     
    }
  };

  const handleSetActive = async (pdfId) => {
    const success = await setPdfActive(restaurant, pdfId); // Set active status to 1
    if (success) {
      fetchPdfsMetadata(); // Refresh the list after setting active
      setDataRefresh(true);
    }
  };

  const handleDownload = async (pdfId) => {

    setDownloadLoadingId(pdfId);

    const response = await getPdfData(restaurant, pdfId);
    if (response) {
      const byteCharacters = atob(response.data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: response.mimetype });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = response.filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);

      setDownloadLoadingId(0);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div id="pdfListBox">
      <h3>Geschiedenis</h3>
      <div className="pdf-grid">
        {!isMobile ? (
          <>
            <div className="pdf-grid-header">
              <div>Naam</div>
              <div>Bestandsnaam</div>
              <div>Datum</div>
              <div>Actief</div>
              <div>Acties</div>
            </div>
            {pdfs.map((pdf) => (
              <div key={pdf.id} className="pdf-grid-row">
                <div>{pdf.name}</div>
                <div>{pdf.filename}</div>
                <div>{formatDate(pdf.timestamp)}</div>
                <div
                  style={
                    pdf.active
                      ? { color: "rgb(85, 116, 110)", fontWeight: "bold" }
                      : {}
                  }
                >
                  {pdf.active ? "Ja" : "Nee"}
                </div>
                <div className="buttonsDiv">
                  <button className="btn-delete" onClick={() => handleDelete(pdf)}>
                    Verwijder
                  </button>
                  <button
                    className="btn-download"
                    onClick={() => handleDownload(pdf.id)}
                  >
                    Download
                  </button>
                  {downloadLoadingId === pdf.id && (
                    <ClipLoader color="#3498db" downloadLoading={true} size={15} />
                  )}
                  {!pdf.active && (
                    <button
                      className="btn-setActive"
                      onClick={() => handleSetActive(pdf.id)}
                    >
                      Maak Actief
                    </button>
                  )}
                </div>
              </div>
            ))}
          </>
        ) : (
          <>
            {pdfs.map((pdf) => (
              <div key={pdf.id} className="pdf-grid-row">
                <div>
                  <span>Naam:</span> <span>{pdf.name}</span>
                </div>
                <div>
                  <span>Bestandsnaam:</span> <span>{pdf.filename}</span>
                </div>
                <div>
                  <span>Datum:</span> <span>{formatDate(pdf.timestamp)}</span>
                </div>
                <div
                  style={
                    pdf.active
                      ? { color: "rgb(85, 116, 110)", fontWeight: "bold" }
                      : {}
                  }
                >
                  <span>Actief:</span> <span>{pdf.active ? "Ja" : "Nee"}</span>
                </div>
                <div className="buttonsDiv">
                  <button className="btn-delete" onClick={() => handleDelete(pdf)}>
                    Verwijder
                  </button>
                  <button
                    className="btn-download"
                    onClick={() => handleDownload(pdf.id)}
                  >
                    Download
                  </button>
                  {downloadLoadingId === pdf.id && (
                    <ClipLoader color="#3498db" downloadLoading={true} size={15} />
                  )}
                  {!pdf.active && (
                    <button
                      className="btn-setActive"
                      onClick={() => handleSetActive(pdf.id)}
                    >
                      Maak Actief
                    </button>
                  )}
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default PDFList;
