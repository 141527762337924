import React, { useState} from 'react';
import { Link } from 'react-router-dom';
import './styling/thankYou.css';
//import thankYouImage from './assets/thankYou.png'; // Make sure you have a thank you image in your assets

const ThankYou = () => {

  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 1200px)").matches
  );

  return (
    <>
    {!isMobile && (
    <div className="desktopNavSpacer"></div>
     )}
    <div id="thankYouWrapper">
      <div id="thankYouBox">
        <h1>Bedankt voor je bestelling!</h1>
        {/* <img src={thankYouImage} alt="Thank You" className="thankYouImage" /> */}
        <p>We hebben je bestelling succesvol ontvangen en zijn deze nu aan het verwerken.</p>
        <p>Een bevestigingsmail is verstuurd naar je e-mailadres.</p>
        <Link to="/" className="btn-pulci-green">Terug naar Home</Link>
      </div>
    </div>
    </>
  );
};

export default ThankYou;
