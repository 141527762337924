import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getAllOrders } from '../../dataSource/OrderSource';
import './styling/adminOrders.css';

const AdminOrders = () => {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [filters, setFilters] = useState({
    status: '',
    paymentStatus: '',
  });
  const [searchQuery, setSearchQuery] = useState('');

  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 1200px)").matches
  );

  useEffect(() => {
    async function fetchData() {
      const result = await getAllOrders();
      const sortedOrders = result.sort((a, b) => b.id - a.id);      
      setOrders(sortedOrders);
      setFilteredOrders(sortedOrders);
    }
    fetchData();
  }, []);

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  useEffect(() => {
    let sortedOrders = [...filteredOrders];
    if (sortConfig.key !== null) {
      sortedOrders.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
    setFilteredOrders(sortedOrders);
  }, [sortConfig]);

  useEffect(() => {
    let filtered = orders;
    if (filters.status) {
      filtered = filtered.filter(order => order.status === filters.status);
    }
    if (filters.paymentStatus) {
      filtered = filtered.filter(order => order.payment_status === filters.paymentStatus);
    }
    if (searchQuery) {
      filtered = filtered.filter(order =>
        order.customer.email_address.toLowerCase().includes(searchQuery.toLowerCase()) ||
        order.address.city.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }
    setFilteredOrders(filtered);
  }, [filters, searchQuery, orders]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <>
    {!isMobile && (
      <div className="desktopNavSpacer"></div>
       )}
    <div id='adminOrdersWrapper'>
      <div className="container">
      <Link to={`/admin`} className="action-link"><button className='btn-pulci-green btn-back'>Terug naar menu</button></Link>
        <h1>Alle Bestellingen</h1>
        <div className="filters">
          <label>
            Status:
            <select name="status" value={filters.status} onChange={handleFilterChange}>
              <option value="">Alle</option>
              <option value="Created">Aangemaakt</option>
              <option value="Received">Ontvangen</option>
              <option value="Processed">Verwerkt</option>
              <option value="Shipped">Verzonden</option>
              <option value="Completed">Afgerond</option>
            </select>
          </label>        
          <input
            type="text"
            placeholder="Zoek op klant email of stad"
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </div>
        {!isMobile ? (
        <table>
          <thead>
            <tr>
              <th onClick={() => handleSort('id')}>ID</th>
              <th onClick={() => handleSort('price')}>Prijs</th>
              <th onClick={() => handleSort('customer.email_address')}>Klant Email</th>
              <th onClick={() => handleSort('address.city')}>Stad</th>
              <th onClick={() => handleSort('order_date')}>Besteldatum</th>
              <th onClick={() => handleSort('status')}>Status</th>
              <th onClick={() => handleSort('payment_status')}>Betaling ID</th>
              <th>Acties</th>
            </tr>
          </thead>       
          <tbody>
            {filteredOrders.map(order => (
              <tr key={order.id}>
                <td>{order.id}</td>
                <td>€{order.price}</td>
                <td>{order.customer.email_address}</td>
                <td>{order.address.city}</td>
                <td>{order.order_date}</td>
                <td>{order.status}</td>
                <td>{order.payment_id}</td>
                <td>
                  <Link to={`/admin/order/${order.id}`} className="action-link"><button className='btn-download'>Details Bekijken</button></Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        ) : (
        <table>           
         <tbody>
            {filteredOrders.map(order => (
              <tr key={order.id}>
                <td><span>ID:</span><span>{order.id}</span></td>
                <td><span>Prijs:</span><span>€{order.price}</span></td>
                <td><span>Email:</span><span>{order.customer.email_address}</span></td>
                <td><span>Stad:</span><span>{order.address.city}</span></td>
                <td><span>Datum:</span><span>{order.order_date}</span></td>
                <td><span>Status:</span><span>{order.status}</span></td>
                <td><span>Betalings ID:</span><span>{order.payment_id}</span></td>
                <td>
                  <Link to={`/admin/order/${order.id}`} className="action-link"><button className='btn-download'>Details Bekijken</button></Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        )}
      </div>
    </div>
    </>
  );
};

export default AdminOrders;
