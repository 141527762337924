// src/util/cartContext.js
import React, { createContext, useState, useEffect } from 'react';
import { getWithExpiryAndDecrypt, setWithExpiryAndEncrypt } from './storageFunctions'; // Import your storage functions

export const CartContext = createContext();

const CartProvider = ({ children }) => {
    
  const [cart, setCart] = useState(() => {
    const savedCart = getWithExpiryAndDecrypt('cart'); // Retrieve and decrypt cart data
    return savedCart ? savedCart : [];
  });

  useEffect(() => {
    setWithExpiryAndEncrypt('cart', JSON.stringify(cart), 24 * 60 * 60 * 1000); // Encrypt and store cart data with expiry
  }, [cart]);

  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    const newTotalPrice = cart.reduce((total, item) => {
      return total + item.price * item.quantity;
    }, 0);
    setTotalPrice(newTotalPrice);
  }, [cart]);  

  const addToCart = (product, amount) => {

    console.log("adding: ", product);

    setCart(prevCart => {
      const existingProduct = prevCart.find(item => item.name === product.name);
      if (existingProduct) {
        console.log("Exisiting product...");
        return prevCart.map(item =>
          item.name === product.name ? { ...item, quantity: item.quantity + amount } : item
        );
      } else {
        console.log("Non-Exisiting product...");
        return [...prevCart, { ...product, quantity: amount }];
      }
    });
  };


  const removeFromCart = (productName) => {
    setCart(prevCart => prevCart.reduce((acc, item) => {
      if (item.name === productName) {
        if (item.quantity > 1) {
          acc.push({ ...item, quantity: item.quantity - 1 });
        }
      } else {
        acc.push(item);
      }
      return acc;
    }, []));
  };

  const removeItem = (productName) => {
    setCart((prevCart) => {
      const updatedCart = prevCart.filter(item => {
        return item.name !== productName;
      });
      return updatedCart;
    });
  };

  const clearCart = () => {
    setCart([]);
  };

  return (
    <CartContext.Provider value={{ cart, addToCart, removeFromCart, clearCart, removeItem, totalPrice }}>
      {children}
    </CartContext.Provider>
  );
};

export default CartProvider;
