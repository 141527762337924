import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./styling/restraunt.css";
import './styling/restraunt-s.css';
import pdfMenuPulcinos from "../pdf/latest_menu_pulcinos.pdf";
import pdfMenuSatiata from "../pdf/latest_menu_satiata.pdf";
import about from "./assets/about.jpg";

function About() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 1200px)").matches
  );

  const handleOpenPdf = (restaurant) => {
    if (restaurant == "pulcinos") {
      if (pdfMenuPulcinos) {
        window.open(pdfMenuPulcinos, "_blank");
      } else {
        alert("Er is iets mis gegaan met het openen van het Menu...");
      }
    } else if (restaurant == "satiata") {
      if (pdfMenuSatiata) {
        window.open(pdfMenuSatiata, "_blank");
      } else {
        alert("Er is iets mis gegaan met het openen van het Menu...");
      }
    }
  };

  const location = useLocation();

  useEffect(() => {
    // Check if there's a hash in the URL (e.g., #team)
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <>
    {!isMobile && (
    <div className="desktopNavSpacer"></div>
     )}
     
      <div id="aboutBox">
        <div id="aboutTitleWrapper">
          <h1>
            Over <span className="yellow">Pulcinos</span>
          </h1>
        </div>

        <div id="aboutSplitter">
          <div id="aboutImageSlider">
            <img src={about} alt="about"></img>
          </div>

          <div id="aboutInfo">
            <h2>Hoe het allemaal is begonnen...</h2>

            <p>
              Als chef-kok, droomde ik al jaren van mijn eigen delicatessenzaak
              met eerlijke en vooral heerlijke producten in combinatie met een
              klein restaurant met lekker eten in een ongedwongen sfeer. En met
              Pulcinos & Satiata komt dit perfect samen. Samen met chef-kok Puck
              van Baast en Mattijs van der Meulen word hier een eerlijk, vers en
              klein menu bereid.
            </p>

            <p>
              Pulcinos is in 2018 ontstaan. Een aangetrouwde oom uit Falcone,
              een mooi plekje op het Italiaanse eiland Sicilië maakte altijd al
              volgens hetzelfde familierecept, met Siciliaanse citroenen,
              Limoncello. Helaas is deze oom in 2012 overleden, maar de liefde
              die hij had voor het eiland heeft hij doorgegeven aan onze
              familie. We gingen daar regelmatig op vakantie en proefde daar het
              eten en de limoncello van zijn vader. Als wij op Sicilië waren,
              noemde de lokale bevolking mij ‘pulcino’. Pulcino betekent
              kuikentje in het Italiaans. Deze bijnaam kreeg ik omdat ik
              goudblonde haren kreeg in de zon.{" "}
            </p>

            <p>
              Van de vader van mijn oom, Francesco Milone, leerde ik op mijn 16e
              de geheimen van zijn zelfgemaakte limoncello. Thuis in Nederland
              ging ik daarmee experimenteren en de limoncello werd bij
              verschillende familieaangelegenheden gedronken. In 2018 heb ik
              besloten om dit experiment uit te breiden en daarom ben ik
              begonnen met verkopen van Pulcinos Liquor, mijn eigen limoncello.
            </p>
          </div>
        </div>

        <div id="aboutBottomText">
          <h2>Mijn ambitie kunnen waarmaken...</h2>

          <p>
            Daarnaast heb ik jarenlange ervaring opgedaan in de horeca als
            chef-kok. Toen ik Pulcinos Liquor oprichtte, had ik al de ambitie om
            een eigen zaak te beginnen waar mijn liefde voor eten en drinken
            samenkomen. In augustus 2020 heb ik die ambitie eindelijk kunnen
            waarmaken.
          </p>
          <p>
            Pulcinos Food is een knusse, laagdrempelige zaak die werkt met
            eerlijke en lokale producten. Kom gerust langs voor een praatje, een
            diner of gewoon een ongedwongen borrel. Mijn deur staat vijf dagen
            per week open voor iedereen. Salute!
          </p>
        </div>
      </div>

    </>
  );
}

export default About;
