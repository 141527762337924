import React, {useState, useEffect} from 'react';
import { Navigate, useParams } from 'react-router-dom';

export const isAuthenticated = async () => {

  const token = localStorage.getItem('Token'); // Retrieve the token from local storage   

  //const response = await fetch('http://127.0.0.1:5009/validate', {
  const response = await fetch('https://jouwklussenbedrijf.nl/dataservice/validate', {    
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
  });

  return await response.ok;
  
};

// Create a ProtectedRoute component
const ProtectedRoute = ({ children }) => {


  const [authenticated, setAuthenticated] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAuthentication = async () => {
      const isAuthenticatedResult = await isAuthenticated();
      setAuthenticated(isAuthenticatedResult);
      setLoading(false);
    };
    checkAuthentication();
  }, []);

  if (loading) {
     return <div></div>;
  }

  return authenticated ? children : <Navigate to="/login" />;

};


export default ProtectedRoute;
