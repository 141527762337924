import CryptoJS from 'crypto-js';

// Function to get an item from local storage with expiry and decrypt it
export const getWithExpiryAndDecrypt = (key) => {

    const itemString = localStorage.getItem(key);
    if (!itemString) return null;
  
    try {
      // Parse the item from local storage
      const item = JSON.parse(itemString);
  
      // Check if the item has expired
      const now = new Date();
      if (now.getTime() > item.expiry) {
        localStorage.removeItem(key);
        return null;
      }
        
      // Decrypt the data
      const decryptedData = CryptoJS.AES.decrypt(item.data, 'n@7q+6Z^KbW$P5hY').toString(CryptoJS.enc.Utf8);
      const parsedData = JSON.parse(JSON.parse(decryptedData));  
      
      return parsedData;

    } catch (error) {
      console.error('Error decrypting data:', error);
      return null;
    }
  };

// Function to set an item in local storage with expiry and encrypt it
export const setWithExpiryAndEncrypt = (key, value, ttl) => {
  const now = new Date();
  const item = {
    data: CryptoJS.AES.encrypt(JSON.stringify(value), 'n@7q+6Z^KbW$P5hY').toString(),
    expiry: now.getTime() + ttl,
  };
  localStorage.setItem(key, JSON.stringify(item));
};
