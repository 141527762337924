import React, { useState, useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './styling/sliderSatiata.css';

import image1 from './assets/sliderSatiataStore/1.jpeg';
import image2 from './assets/sliderSatiataStore/2.jpeg';
import image3 from './assets/sliderSatiataStore/3.jpg';
import image4 from './assets/sliderSatiataStore/4.jpg';
import image5 from './assets/sliderSatiataStore/5.jpg';
import image6 from './assets/sliderSatiataStore/6.jpg';
import image7 from './assets/sliderSatiataStore/7.jpg';
import image8 from './assets/sliderSatiataStore/8.jpeg';
import image9 from './assets/sliderSatiataStore/9.jpg';
import image10 from './assets/sliderSatiataStore/10.jpg';
import image11 from './assets/sliderSatiataStore/11.jpg';
import image12 from './assets/sliderSatiataStore/12.jpg';
import image13 from './assets/sliderSatiataStore/13.jpg';
import image14 from './assets/sliderSatiataStore/14.jpg';
import image15 from './assets/sliderSatiataStore/15.jpg';
import image16 from './assets/sliderSatiataStore/16.jpg';
import image17 from './assets/sliderSatiataStore/17.jpg';
import image18 from './assets/sliderSatiataStore/18.jpg';
import image19 from './assets/sliderSatiataStore/19.jpg';
import image20 from './assets/sliderSatiataStore/20.jpg';

const ImagesliderSatiataStore = () => {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);

  const slider1 = useRef(null);
  const slider2 = useRef(null);

  const settingsMain = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    lazyLoad: 'ondemand', 
    arrows: false,
    responsive: [
      {
        breakpoint: 2024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5
        }
      },
      {
        breakpoint: 1624,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4
        }
      },
      {
        breakpoint: 1324,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,          
          arrows:true,
          dots:true,
        }
      }
    ],
  };


  const images = [
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
    image10,
    image11,
    image12,
    image13,
    image14,
    image15,
    image16,
    image17,
    image18,
    image19,
    image20
  ];

  return (
    <div data-aos="zoom-in-up" id='sliderBox'>
      {/* Main Image Slider */}
      <Slider
        {...settingsMain}
        ref={(slider) => setNav1(slider)}
        className="slider-for"
      >
        {images.map((image, index) => (
          <div key={index}>
            <img
              src={image}
              alt={`slide-${index}`}
              style={{ width: '100%', height: '600px', objectFit: 'cover' }}
            />
          </div>
        ))}
      </Slider>

      {/* Thumbnail Slider */}
      <Slider
        ref={(slider) => setNav2(slider)}
        className="slider-nav"
      >
        
      </Slider>
    </div>
  );
};

export default ImagesliderSatiataStore;
