import React, { useContext, useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import basket from './assets/basket.svg';
import basketYellow from './assets/basket-yellow.svg';
import plus from './assets/plus.svg';
import xCross from "./assets/x-white.svg";
import minus from './assets/minus.svg';
import trash from './assets/trash.svg';
import { CartContext } from '../util/cartContext'; 
import './styling/navCart.css'; // Make sure to create and import the CSS file

const NavCart = ({handleLinkClick, navRef}) => {

  const location = useLocation();
  
  const { cart, addToCart, removeFromCart, clearCart, totalPrice, removeItem } = useContext(CartContext);     
  const [productCount, setProductCount] = useState(0);
  const basketRef = useRef(null);

  const openBasket = () => {
    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
    basketRef.current.classList.remove('hide');
    document.documentElement.style.overflow = 'hidden';
    document.body.style.paddingRight = `${scrollbarWidth}px`;
    
    // Add padding to nav if it’s absolutely positioned
    if (navRef.current) {
      navRef.current.style.paddingRight = `${scrollbarWidth}px`;
    }
  };
  
  const closeBasket = () => {
    basketRef.current.classList.add('hide');
    document.documentElement.style.overflow = 'auto';
    document.body.style.paddingRight = '0px';
    
    // Remove padding from nav
    if (navRef.current) {
      navRef.current.style.paddingRight = '0px';
    }
  };

  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 1000px)").matches
  );

  useEffect(() => {
    let count = 0;
    cart.map(product => {
      count += product.quantity;
    });
    setProductCount(count);
  }, [cart]);

  return (
    <div id='basket'>

      {!isMobile ? (
        <>
        <img onClick={() => openBasket()} src={basket} alt='basket' />
        <p id="basketCounter">{productCount}</p>    
        </>
      ) : (
        <Link onClick={handleLinkClick} to="/cart" state={{ from: location.pathname }}>             
        <img src={basket} alt='basket' />
        <p id="basketCounter">{productCount}</p> 
        </Link>
      )}

     

      <div id='basketProducts' className='hide' ref={basketRef}>
        <p id='basketCloseBtn' onClick={() => closeBasket()}><img src={xCross}></img></p>

        <div data-aos="zoom-in" className="cart-overview">
        <h2><img src={basketYellow} alt=''></img>Winkelwagentje</h2>
        {cart.length === 0 ? (
          <p>Het winkelwagentje is leeg</p>
        ) : (
          <>
          <ul>
            {cart.map(item => (
              <li key={item.id}>
                <h3>{item.name}</h3>         
                <div className='cartLineWrapper'>     
                {/* <div className="quantity-control">
                <button onClick={() => removeFromCart(item.name)}><img src={minus} alt='-'></img></button>
                <span>{item.quantity}</span>
                <button onClick={() => addToCart(item, 1)}><img src={plus} alt='+'></img></button>              
                </div> */}
                <div className='priceBox'>
                 <p>€{(item.price * item.quantity).toFixed(2)}</p>
                    <div className="quantity-controls">
                      <button onClick={() => removeFromCart(item.name)}><img src={minus} alt='-' /></button>
                      <span>{item.quantity}</span>
                      <button onClick={() => addToCart(item, 1)}><img src={plus} alt='+' /></button>
                      <button className="remove-item-btn" onClick={() => removeItem(item.name)}><img src={trash} alt='Verwijder' /></button>
                    </div>
                  </div>
                </div>  
              </li>
            ))}
          </ul>
            <div className="cart-actions">
              <p onClick={clearCart} className="clear-cart-btn "><img src={trash} alt='Verwijder' /> Winkelwagentje legen</p>
            </div>  
            </>  
        )}
        </div>

        <div data-aos="zoom-in" className="cart-overview">
          <h2>Overzicht</h2>
          <div className="overview-details">
            <p>Producten ({productCount})</p>
            <p>€ {totalPrice.toFixed(2)}</p>
          </div>
          <div className="overview-details">
            <p>Verzendkosten</p>
            <p>€ 6.95</p>
          </div>
          <div className="overview-total">
            <p>Totaal:</p>
            <p>€ {(totalPrice + 6.95).toFixed(2) }</p>
          </div>
          <Link to="/cart"><button onClick={closeBasket}>Winkelwagentje bekijken</button></Link>

          </div>

       
      </div>
    </div>
  );
};

export default NavCart;
