import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./styling/restraunt.css";
import './styling/restraunt-s.css';
import ImageSlider from "../components/Slider";
import pdfMenuPulcinos from "../pdf/latest_menu_pulcinos.pdf";
import bg from "../components/assets/slider/2.jpg";
import bg2 from "../components/assets/slider/3.jpg";
import restraunt from "./assets/restraunt.jpg";
import limoncello from "./assets/limoncello.jpg";
import gin from "./assets/gin.jpg";
import yuzu from "./assets/yuzu.jpg";
import lemon from "./assets/lemon.png";
import lemonSlice from "./assets/lemonSliceTransparant.png";
import mapsIconPulci from "./assets/mapsPulci.svg";
import mapsIconSatiata from "./assets/mapsSatiata.svg";
import instaYellow from "./assets/insta-yellow.svg";
import glassBg from "./assets/glass-bg.jpg";

import pdfMenuSatiata from "../pdf/latest_menu_satiata.pdf";
import gradientDown from "./assets/waves-down.svg";
import gradientUp from "./assets/waves-up.svg";
import gradientGreen from "./assets/waves-green.svg";
import ImageSliderSatiata from "../components/SliderSatiata";
import octo from "./assets/octo.JPG";
import logoSatiata from "./assets/logoSatiata.svg";
import instaRed from "./assets/insta-red.svg";

import storeFront from "./assets/liquorStoreFront.jpg";
import kunstRoof from "./assets/kunstroof-transparent.png";
import ImagesliderSatiataStore from "../components/SliderSatiataStore";
import logoBeige from "./assets/logoSatiataBeige.webp";

import logoTrans from "./assets/logoTrans.png";
import ContactForm from "../components/ContactForm";
import yuzuu from "./assets/yuzuu.webp";

import cirkles from "./assets/cirkles.svg";
import about from "./assets/about.jpg";

import mail from "./assets/mail-s.svg";
import insta from "./assets/insta-s.svg";
import phone from "./assets/phone-s.svg";


function Restraunt() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 1200px)").matches
  );

  const handleOpenPdf = (restaurant) => {
    if (restaurant == "pulcinos") {
      if (pdfMenuPulcinos) {
        window.open(pdfMenuPulcinos, "_blank");
      } else {
        alert("Er is iets mis gegaan met het openen van het Menu...");
      }
    } else if (restaurant == "satiata") {
      if (pdfMenuSatiata) {
        window.open(pdfMenuSatiata, "_blank");
      } else {
        alert("Er is iets mis gegaan met het openen van het Menu...");
      }
    }
  };

  const location = useLocation();

  useEffect(() => {
    // Check if there's a hash in the URL (e.g., #team)
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <>
      <div id="homeBox">
        <div className="navWrapper"></div>

        <img src={restraunt} alt="background" class="background-image"></img>

        <div id="restrauntAnchor" className="splitterBox">
          <div className="infoBox">
            <div className="infoWrapper">
              <h1>
                Welkom bij <span className="yellow">Pulcinos</span>
              </h1>

              <p>
                Aan de Piushaven in Tilburg vind je Restaurant Pulcinos, de
                perfecte plek om te genieten van lekker eten, goede drankjes en
                een ontspannen sfeer. Of je nu komt voor een diner, een borrel
                of een cocktail aan de bar, je bent voor alles welkom.
              </p>

              <p>
                We serveren smaakvolle gerechten gemaakt met verse ingrediënten
                en hebben een mooie selectie cocktails en borrelhapjes. Veel van
                de cocktails zijn gemaakt met onze eigen dranken zoals de
                Limoncello, Valenti en Yuzu gin. Veel gerechten zijn
                geïnspireerd door de Italiaanse keuken maar er word eten van
                over heel de wereld geserveerd.{" "}
              </p>

              <p>
                Kom langs en geniet van alles wat Pulcinos te bieden heeft – een
                fijne plek aan het water, goed eten, drinken en gezelligheid!
              </p>

              <p>Reserveer via de knop hieronder!</p>

              <div className="buttonsBox">
                <a
                  href="https://restaurant-pulcinos.lurch.app/reservation/"
                  rel="noreferrer"
                  target="_blank"
                >
                  <button className="checkout-btn">Reserveer een Tafel</button>
                </a>

                <button
                  className="checkout-btn"
                  onClick={() => handleOpenPdf("pulcinos")}
                >
                  Bekijk Menu
                </button>

                <a
                  href="https://maps.app.goo.gl/uMM7iNZJsabxVUm5A"
                  rel="noreferrer"
                  target="_blank"
                >
                  <button className="checkout-btn mapsButton">
                    <img src={mapsIconPulci} alt=""></img>
                    Adres
                  </button>
                </a>
                <a
                  href="https://www.instagram.com/pulcinostilburg/"
                  rel="noreferrer"
                  target="_blank"
                >
                  <img className="instaLogo" src={instaYellow} alt=""></img>
                </a>
              </div>
            </div>
          </div>

          <div className="imageBox">
            <div>
              <ImageSlider />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Restraunt;
