import React, { useContext, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { CartContext } from '../util/cartContext';
import './styling/cart.css';
import './styling/cart-s.css';
import { Link } from "react-router-dom";
import paypal from "./assets/paypal.svg";
import visa from "./assets/visa.svg";
import mastercard from "./assets/mastercard.svg";
import ideal from "./assets/ideal.svg";
import plus from './assets/plus.svg';
import minus from './assets/minus.svg';
import trash from './assets/trash.svg';
import limoncello from "../pages/assets/limoncello.jpg";
import valenti from "../pages/assets/valenti.jpg";
import yuzu from "../pages/assets/yuzu.jpg";
import valentiCoctail from "../pages/assets/valentiCoctail.png";
import yuzuGin from "../pages/assets/gin.jpg";
import arrow from "../pages/product pages/assets/arrow-back.svg";

const Cart = () => { 

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { cart, addToCart, removeFromCart, clearCart, totalPrice, removeItem } = useContext(CartContext);
  const [productCount, setProductCount] = useState(0);
  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 1200px)").matches
  );

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    let count = 0;
    cart.map(product => {
      count += product.quantity;
    });
    setProductCount(count);
  }, [cart]);

  const previousUrl = location.state?.from || '/webshop';

  const getProductImage = (productName) => {
    switch (productName) {
      case 'Pulcinos Limoncello':
        return limoncello;
      case 'Pulcinos Valenti':
        return valenti;
      case 'Pulcinos Yuzu':
        return yuzu;
      case 'Pulcinos Yuzu Gin':
        return yuzuGin;
      case 'Valenti X San Pellegrino cadeaupakket':
        return valentiCoctail;
      default:
        return null; // Add a default image if necessary
    }
  };
  
  return (
    <>

    {!isMobile && (
    <div className="desktopNavSpacer"></div>
     )}
       <div onClick={() => navigate(previousUrl)} className="back">        
          <img src={arrow} alt=""></img>
          <p>Terug</p>
      </div>
    <div id='cartWrapper'>
    <div id='cartBox'>
      <h2>Winkelwagentje</h2>
      <div className="cart-content">
        <div className="cart-items">
          {cart.length === 0 ? (
            <p>Het winkelwagentje is leeg</p>
          ) : (
            <ul>
            {cart.map(item => (
                  <li key={item.id}>
                    <div className="item-info">
                      <div className='item-info-imageWrapper'>
                      <img src={getProductImage(item.name)} alt={item.name} className="product-image" />
                      </div>
                      <div className='item-info-text'>
                        <h3>{item.name}</h3>
                        <p>Prijs: €{item.price}</p>
                        {isMobile && (
                          <div className="quantity-controls">
                      <button onClick={() => removeFromCart(item.name)}><img src={minus} alt='-' /></button>
                      <span>{item.quantity}</span>
                      <button onClick={() => addToCart(item, 1)}><img src={plus} alt='+' /></button>
                      <button className="remove-item-btn" onClick={() => removeItem(item.name)}><img src={trash} alt='Verwijder' /></button>
                    </div>
                        )}
                      </div>
                    </div>

                    {!isMobile && (
                    <div className="quantity-controls">
                      <button onClick={() => removeFromCart(item.name)}><img src={minus} alt='-' /></button>
                      <span>{item.quantity}</span>
                      <button onClick={() => addToCart(item, 1)}><img src={plus} alt='+' /></button>
                      <button className="remove-item-btn" onClick={() => removeItem(item.name)}><img src={trash} alt='Verwijder' /></button>
                    </div>
                    )}
                  </li>
                ))}
            </ul>
          )}
          {cart.length > 0 && (
            <div className="cart-actions">
              <p onClick={clearCart} className="clear-cart-btn "><img src={trash} alt='Verwijder' /> Winkelwagentje legen</p>
            </div>
          )}
        </div>
        <div data-aos="zoom-in" className="cart-overview">
          <h2>Overzicht</h2>
          <div className="overview-details">
            <p>Producten ({productCount})</p>
            <p>€ {totalPrice.toFixed(2)}</p>
          </div>
          <div className="overview-details">
            <p>Verzendkosten</p>
            <p>€ 6.95</p>
          </div>
          <div className="overview-total">
            <p>Totaal:</p>
            <p>€ {(totalPrice + 6.95).toFixed(2) }</p>
          </div>
          <Link to='/orderdetails'><button className="checkout-btn">Verder naar bestellen</button></Link>
          <div className="payment-icons">
            {/* Add your payment icons here */}
          </div>
          <div className='paymentIcons'>
            <img src={ideal} alt='ideal'></img>
            <img src={paypal} alt='paypal'></img>
            <img src={visa} alt='visa'></img>
            <img src={mastercard} alt='mastercard'></img>            
          </div>
          <p className='paymentIconsP'><strong>Of kies voor gemak met 'Achteraf betalen'</strong></p>
        </div>
      </div>
    </div>
     </div>
     </>
  );
};

export default Cart;
