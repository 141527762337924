import React, { useState, useRef } from 'react';
import { addPdf } from '../dataSource/PDFSource'; // Adjust the import based on your file structure
import './styling/uploadPDF.css'; // Import your CSS file
import { ClipLoader } from 'react-spinners';

const UploadPdfComponent = ({ setDataRefresh, restaurant }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [customName, setCustomName] = useState('');
  const [loading, setLoading] = useState(false);
  const submitButton = useRef(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleNameChange = (event) => {
    setCustomName(event.target.value);
  };

  const handleUpload = async () => {

    if (!selectedFile || !customName) {
      alert('Please select a file and provide a name first!');
      return;
    }

    setLoading(true);
    console.log(submitButton.current);
    if (submitButton.current) {
      submitButton.current.disabled = true;
    }
    console.log("2", submitButton.current);
    const formData = new FormData();
    formData.append('pdf', selectedFile);
    formData.append('name', customName);
    
    console.log("PAssed 1rst ", restaurant);

    const response = await addPdf(restaurant, formData);
    if (response) {
      alert('Menukaart successvol geupload!');
      setDataRefresh(true);
      setLoading(false);
      if (submitButton.current) {
        submitButton.current.disabled = false;
      }
    } else {
      alert('File upload failed');
    }
  };

  return (
    <div className="upload-form">
      <h2>Upload nieuwe menukaart</h2>
      <input type="file" accept="application/pdf" onChange={handleFileChange} />
      <input 
        type="text" 
        placeholder="Naam" 
        value={customName} 
        onChange={handleNameChange} 
      />
      <button ref={submitButton} className="btn-setActive" onClick={handleUpload}>Upload PDF</button>
      <ClipLoader color="#3498db" loading={loading} size={15} />
    </div>
  );
};

export default UploadPdfComponent;
