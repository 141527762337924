import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getAllOrders } from '../../dataSource/OrderSource';
import { getLabelByOrderId } from '../../dataSource/PostNLSource';
import './styling/adminShipping.css';

const AdminShipping = () => {
  const [orders, setOrders] = useState([]);
  const [receivedOrders, setReceivedOrders] = useState([]);
  const [processedOrders, setProcessedOrders] = useState([]);
  const [labelUrls, setLabelUrls] = useState({});
  const [expandedOrders, setExpandedOrders] = useState({});
  const [expandedSections, setExpandedSections] = useState({
    received: true,
    processed: true,
  });

  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 1200px)").matches
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.matchMedia("(max-width: 1200px)").matches);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    async function fetchData() {
      const result = await getAllOrders();
      const sortedOrders = result.sort((a, b) => b.id - a.id);
      setOrders(sortedOrders);

      const received = sortedOrders.filter(order => order.status === 'Ontvangen');
      const processed = sortedOrders.filter(order => order.status === 'Verwerkt');

      setReceivedOrders(received);
      setProcessedOrders(processed);

      await fetchLabels([...received, ...processed]);
    }

    fetchData();
  }, []);

  const fetchLabels = async (orders) => {
    const urls = {};
    for (const order of orders) {
      const shippingLabelObject = await getLabelByOrderId(order.id);
      if (shippingLabelObject && shippingLabelObject.label_data) {
        const base64Data = shippingLabelObject.label_data;
        const binaryString = atob(base64Data);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
          bytes[i] = binaryString.charCodeAt(i);
        }

        const blob = new Blob([bytes], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        urls[order.id] = url;
      }
    }
    setLabelUrls(urls);
  };

  const toggleOrderExpand = (id) => {
    setExpandedOrders((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const toggleSectionExpand = (section) => {
    setExpandedSections((prev) => ({ ...prev, [section]: !prev[section] }));
  };

  return (
    <>
      {!isMobile && <div className="desktopNavSpacer"></div>}
      <div id="adminShippingWrapper">
        <div className="container">
          <Link to={`/admin`} className="action-link">
            <button className="btn-pulci-green btn-back">Terug naar menu</button>
          </Link>
          <h1>Overzicht</h1>

          {/* Section for orders that still need processing */}
          <h2 onClick={() => toggleSectionExpand('received')}>
            Nog te verwerken ({receivedOrders.length}) {expandedSections.received ? '▲' : '▼'}
          </h2>
          {expandedSections.received && (
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  {isMobile ? <th>Acties</th> : (
                    <>
                      <th>Prijs</th>
                      <th>Klant Email</th>
                      <th>Stad</th>
                      <th>Besteldatum</th>
                      <th>Status</th>
                      <th>Acties</th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {receivedOrders.map((order) => (
                  <React.Fragment key={order.id}>
                    <tr>
                      <td><strong>ID:</strong>&nbsp;{order.id}</td>
                      {isMobile ? (
                        <td>
                          <button
                            className="btn-download"
                            onClick={() => toggleOrderExpand(order.id)}
                          >
                            {expandedOrders[order.id] ? 'Verberg' : 'Uitvouwen'}
                          </button>
                          <Link to={`/admin/order/${order.id}`} className="action-link">
                            <button className="btn-download">Details Bekijken</button>
                          </Link>
                          {labelUrls[order.id] && (
                            <a
                              href={labelUrls[order.id]}
                              download={`Verzendlabel_${order.id}.pdf`}
                              className="action-link"
                            >
                              <button className="btn-download">Download Verzendlabel</button>
                            </a>
                          )}
                        </td>
                      ) : (
                        <>
                          <td>€{order.price}</td>
                          <td>{order.customer.email_address}</td>
                          <td>{order.address.city}</td>
                          <td>{order.order_date}</td>
                          <td>{order.status}</td>
                          <td>
                            <Link to={`/admin/order/${order.id}`} className="action-link">
                              <button className="btn-download">Details Bekijken</button>
                            </Link>
                            {labelUrls[order.id] && (
                              <a
                                href={labelUrls[order.id]}
                                download={`Verzendlabel_${order.id}.pdf`}
                                className="action-link"
                              >
                                <button className="btn-download">Download Verzendlabel</button>
                              </a>
                            )}
                          </td>
                        </>
                      )}
                    </tr>
                    {isMobile && expandedOrders[order.id] && (
                      <tr>
                        <td colSpan="2">
                          <div className="order-details">
                            <p><strong>Prijs:</strong> €{order.price}</p>
                            <p><strong>Klant Email:</strong> {order.customer.email_address}</p>
                            <p><strong>Stad:</strong> {order.address.city}</p>
                            <p><strong>Besteldatum:</strong> {order.order_date}</p>
                            <p><strong>Status:</strong> {order.status}</p>
                          </div>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          )}

          {/* Section for orders that are ready to be shipped */}
          <h2 onClick={() => toggleSectionExpand('processed')}>
            Nog te verzenden ({processedOrders.length}) {expandedSections.processed ? '▲' : '▼'}
          </h2>
          {expandedSections.processed && (
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  {isMobile ? <th>Acties</th> : (
                    <>
                      <th>Prijs</th>
                      <th>Klant Email</th>
                      <th>Stad</th>
                      <th>Besteldatum</th>
                      <th>Status</th>
                      <th>Acties</th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {processedOrders.map((order) => (
                  <React.Fragment key={order.id}>
                    <tr>
                      <td><strong>ID:</strong>&nbsp;{order.id}</td>
                      {isMobile ? (
                        <td>
                          <button
                            className="btn-download"
                            onClick={() => toggleOrderExpand(order.id)}
                          >
                            {expandedOrders[order.id] ? 'Verberg' : 'Uitvouwen'}
                          </button>
                          <Link to={`/admin/order/${order.id}`} className="action-link">
                            <button className="btn-download">Details Bekijken</button>
                          </Link>
                          {labelUrls[order.id] && (
                            <a
                              href={labelUrls[order.id]}
                              download={`Verzendlabel_${order.id}.pdf`}
                              className="action-link"
                            >
                              <button className="btn-download">Download Verzendlabel</button>
                            </a>
                          )}
                        </td>
                      ) : (
                        <>
                          <td>€{order.price}</td>
                          <td>{order.customer.email_address}</td>
                          <td>{order.address.city}</td>
                          <td>{order.order_date}</td>
                          <td>{order.status}</td>
                          <td>
                            <Link to={`/admin/order/${order.id}`} className="action-link">
                              <button className="btn-download">Details Bekijken</button>
                            </Link>
                            {labelUrls[order.id] && (
                              <a
                                href={labelUrls[order.id]}
                                download={`Verzendlabel_${order.id}.pdf`}
                                className="action-link"
                              >
                                <button className="btn-download">Download Verzendlabel</button>
                              </a>
                            )}
                          </td>
                        </>
                      )}
                    </tr>
                    {isMobile && expandedOrders[order.id] && (
                      <tr>
                        <td colSpan="2">
                          <div className="order-details">
                            <p><strong>Prijs:</strong> €{order.price}</p>
                            <p><strong>Klant Email:</strong> {order.customer.email_address}</p>
                            <p><strong>Stad:</strong> {order.address.city}</p>
                            <p><strong>Besteldatum:</strong> {order.order_date}</p>
                            <p><strong>Status:</strong> {order.status}</p>
                          </div>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
};

export default AdminShipping;
