import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./styling/footer.css";

import pinBlue from "../pages/assets/pin-blue.svg";
import insta from "../pages/assets/insta.svg";

import xCross from "./assets/x.svg";
import logo from "./assets/logoTrans.png";

import pdfMenuSatiata from "../pdf/latest_menu_satiata.pdf";
import pdfMenuPulcinos from "../pdf/latest_menu_pulcinos.pdf";

function Footer() {
  const [menuSelect, isMenuSelect] = useState(false);

  const handleOpenPdf = (restaurant) => {
    if (restaurant == "pulcinos") {
      if (pdfMenuPulcinos) {
        window.open(pdfMenuPulcinos, "_blank");
      } else {
        alert("Er is iets mis gegaan met het openen van het Menu...");
      }
    } else if (restaurant == "satiata") {
      if (pdfMenuSatiata) {
        window.open(pdfMenuSatiata, "_blank");
      } else {
        alert("Er is iets mis gegaan met het openen van het Menu...");
      }
    }
  };

  return (
    <footer>
      {menuSelect && (
        <div id="menuSelectorBox">
          <div className="menuSelectorWrapper">
            <img onClick={() => isMenuSelect(false)} src={xCross} alt="x"></img>

            <h3>Welk menu wil je bekijken?</h3>

            <div className="menuSelectButtons">
              <button onClick={() => handleOpenPdf("pulcinos")}>
                Menu Pulcinos
              </button>
              <button onClick={() => handleOpenPdf("satiata")}>
                Menu Satiata
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="footerCol">
        <img className="footerLogo" src={logo} alt="Pulcinos logo"></img>

        <div className="footerHotLinks">
          <a onClick={() => isMenuSelect(true)}>
            <p>Menu</p>
          </a>
          <p className="yellow linkSpacer"> | </p>
          <Link to="/webshop">
            <p>Webshop</p>
          </Link>
          <p className="yellow linkSpacer"> | </p>
          <Link to="/restaurant/#contactBox">
            <p>Contact</p>
          </Link>
        </div>

        <button className="checkout-btn">Reserveer een Tafel</button>
      </div>

      <p className="copyRight">© Pulcinos 2024 ⋅ Alle rechten voorbehouden.</p>
    </footer>
  );
}

export default Footer;
