import React, { createContext, useState, useEffect } from "react";

export const AgeVerificationContext = createContext();

export const AgeVerificationProvider = ({ children }) => {
  const [isOver18, setIsOver18] = useState(() => {
    // Check session storage for saved age verification status
    const storedStatus = sessionStorage.getItem("isOver18");
    return storedStatus === "true"; // Convert to boolean
  });

  useEffect(() => {
    // Update session storage whenever isOver18 changes
    sessionStorage.setItem("isOver18", isOver18);
  }, [isOver18]);

  return (
    <AgeVerificationContext.Provider value={{ isOver18, setIsOver18 }}>
      {children}
    </AgeVerificationContext.Provider>
  );
};
