import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./styling/restraunt.css";
import './styling/restraunt-s.css';
import pdfMenuPulcinos from "../pdf/latest_menu_pulcinos.pdf";
import mapsIconSatiata from "./assets/mapsSatiata.svg";
import pdfMenuSatiata from "../pdf/latest_menu_satiata.pdf";
import ImageSliderSatiata from "../components/SliderSatiata";
import octo from "./assets/octo.JPG";
import logoSatiata from "./assets/logoSatiata.svg";
import instaRed from "./assets/insta-red.svg";


function SatiataRestaurant() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 1200px)").matches
  );

  const handleOpenPdf = (restaurant) => {
    if (restaurant == "pulcinos") {
      if (pdfMenuPulcinos) {
        window.open(pdfMenuPulcinos, "_blank");
      } else {
        alert("Er is iets mis gegaan met het openen van het Menu...");
      }
    } else if (restaurant == "satiata") {
      if (pdfMenuSatiata) {
        window.open(pdfMenuSatiata, "_blank");
      } else {
        alert("Er is iets mis gegaan met het openen van het Menu...");
      }
    }
  };

  const location = useLocation();

  useEffect(() => {
    // Check if there's a hash in the URL (e.g., #team)
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <>
      {!isMobile && (
    <div className="desktopNavSpacer"></div>
     )}
         <div id="satiataBox">
        <div id="satiataAnchor" className="splitterBox">
          <div className="imageBoxSatiata">
            <div>
              <ImageSliderSatiata />
            </div>
          </div>

          <div className="infoBox">
            <div className="infoImageWrapper">
              <img className="octoImage" src={octo} alt=""></img>
              <img
                className="logoImageSatiata"
                src={logoSatiata}
                alt="Satiata Logo"
              ></img>
            </div>

            <div className="infoWrapper">
              <h1>
                Welkom bij <span className="satiataRed">Satiata</span>
              </h1>

              <p>
                Satiata is het kleine zusje van Pulcinos, gelegen aan de
                Nieuwlandstraat in Tilburg. In onze gezellige, intieme zaak
                serveren we handgemaakte, verse gerechtjes die perfect te
                combineren zijn met een groot assortiment aan wijnen. De wijnen
                komen van onze eigen slijterij, pal naast de deur.
              </p>

              <p>
                Bij Satiata draait alles om genieten in een relaxte sfeer. Naast
                de vele wijnen kun je hier ook proeven van onze eigen Pulcinos
                likeuren en natuurwijnen. Kom langs voor een heerlijke avond vol
                smaken en gezelligheid!{" "}
              </p>

              <p>
                Bij Satiata ben je welkom voor lunch, diner of gewoon een borrel
                met een hapje.
              </p>

              <div className="buttonsBox">
                <button
                  className="checkout-btn"
                  onClick={() => handleOpenPdf("satiata")}
                >
                  Bekijk Menu
                </button>
                <a
                  href="https://maps.app.goo.gl/duKn74KsMC3eNcXWA"
                  rel="noreferrer"
                  target="_blank"
                >
                  <button className="checkout-btn mapsButton">
                    <img src={mapsIconSatiata} alt=""></img>
                    Adres
                  </button>
                </a>
                <a
                  href="https://www.instagram.com/satiata.tilburg/"
                  rel="noreferrer"
                  target="_blank"
                >
                  <img className="instaLogo" src={instaRed} alt=""></img>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
}

export default SatiataRestaurant;
