import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./styling/restraunt.css";
import './styling/restraunt-s.css';
import pdfMenuPulcinos from "../pdf/latest_menu_pulcinos.pdf";
import mapsIconSatiata from "./assets/mapsSatiata.svg";
import pdfMenuSatiata from "../pdf/latest_menu_satiata.pdf";
import logoSatiata from "./assets/logoSatiata.svg";
import instaRed from "./assets/insta-red.svg";
import storeFront from "./assets/liquorStoreFront.jpg";
import kunstRoof from "./assets/kunstroof-transparent.png";
import ImagesliderSatiataStore from "../components/SliderSatiataStore";
import logoBeige from "./assets/logoSatiataBeige.webp";

function SatiataStore() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 1400px)").matches
  ); 
  
  const [showNav, setShowNav] = useState(
    window.matchMedia("(max-width: 1200px)").matches
  );


  const handleOpenPdf = (restaurant) => {
    if (restaurant == "pulcinos") {
      if (pdfMenuPulcinos) {
        window.open(pdfMenuPulcinos, "_blank");
      } else {
        alert("Er is iets mis gegaan met het openen van het Menu...");
      }
    } else if (restaurant == "satiata") {
      if (pdfMenuSatiata) {
        window.open(pdfMenuSatiata, "_blank");
      } else {
        alert("Er is iets mis gegaan met het openen van het Menu...");
      }
    }
  };

  const location = useLocation();

  useEffect(() => {
    // Check if there's a hash in the URL (e.g., #team)
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <>  

{!showNav && (
    <div className="desktopNavSpacer"></div>
     )}
     
      <div id="storeBox">
        <div id="storeBoxSplitter">
          <div id="storeBoxInfo">
            <div id="storeBoxTextWrapper">
            
              <h1>
                Welkom bij{" "}
                <span className="satiataRed">Satiata Wine & Liquor</span>
              </h1>

              <p>
                Bij <span className="satiataRed">Satiata Wine & Liquor</span>{" "}
                ontdek je een unieke winkelervaring, waar we onze passie voor
                ambachtelijke dranken combineren met een bijzondere collectie
                wijnen en sterke dranken. Onze winkel is gevuld met zorgvuldig
                geselecteerde producten, waaronder onze eigen huisgemaakte
                likeuren, natuurwijnen en een breed scala aan klassiekers zoals
                whisky, cognac, gin en nog veel meer.
              </p>

              <p>
                Een van de highlights van onze winkel is ons assortiment aan
                huisgemaakte likeuren. Elk recept is met liefde ontwikkeld en we
                gebruiken alleen de beste ingrediënten. Onze trots is de
                klassieke limoncello, gemaakt volgens een traditioneel
                Siciliaans familierecept. Deze limoncello brengt je met één slok
                naar de zonnige kusten van Italië.
              </p>

              <p>
                Daarnaast bieden we de Valenti, onze biologische
                sinaasappellikeur met kaneel, vanille en Jamaicaanse peper. Deze
                bijzondere combinatie van smaken zorgt voor een warme, kruidige
                twist die elke cocktail naar een hoger niveau tilt. Voor de
                liefhebbers van unieke smaken hebben we ook een yuzu likeur,
                gemaakt van verse Japanse yuzu. Deze citrusvrucht brengt een
                frisse, exotische smaak.
              </p>

              <p>
                Verder hebben we ook een yuzu gin in ons assortiment, waar de
                verse yuzu de ster van de show is. Deze gin is perfect voor een
                frisse en onderscheidende G&T.
              </p>

              <div className="storeBoxLogoWrapper">
                <img
                  className="logoImageSatiata"
                  src={logoSatiata}
                  alt="Satiata Logo"
                ></img>
              </div>
             
            </div>
          </div>

          <div id="storeBoxImage">
            <div className="overlay-gradient"></div>
            <img src={storeFront} alt=""></img>
          </div>
        </div>

        <div id="storeBoxBottomText">

        {!isMobile && (
          <div>
            <img
              className="kunstRoofLogo"
              src={kunstRoof}
              alt="Kunstroof Logo"
            ></img>
          </div>
           ) }

          <div style={{position:"relative"}}>

         
          <img
                  className="textlogo"
                  src={logoBeige}
                  alt="Satiata Logo"
                ></img>
            <p>
              Naast onze eigen dranken vind je bij{" "}
              <span className="satiataRed">Satiata Wine & Liquor</span> een
              uitgebreide selectie natuurwijnen en klassieke wijnen, zorgvuldig
              uitgekozen door onze experts. Van verfijnde rode en witte wijnen
              tot mousserende varianten, er is voor elke smaak iets te vinden.
              We hebben ook een uitgebreide collectie whisky’s, cognacs en gins,
              die de perfecte aanvulling zijn op elke goed gevulde drankkast.
            </p>

            <p>
              Maar dat is niet alles. Bij Satiata draait het om meer dan alleen
              dranken. In onze winkel vind je ook een selectie vintage
              merkkleding onder de naam{" "}
              <span style={{ color: "#A52231" }}>Kunstroof</span>. Deze unieke
              collectie combineert mode met geschiedenis en stijl, en biedt de
              perfecte kans om iets bijzonders te scoren tijdens je bezoek. Of
              je nu op zoek bent naar een unieke fles drank of een stukje mode
              met een verhaal, bij ons ben je aan het juiste adres.
            </p>

            {isMobile && (
            <div className="kunstRoofLogoWrapper">
            <img
              className="kunstRoofLogo"
              src={kunstRoof}
              alt="Kunstroof Logo"
            ></img>
          </div>
           ) }

            <p>
              Kom langs bij{" "}
              <span className="satiataRed">Satiata Wine & Liquor</span> en laat
              je verrassen door onze ambachtelijke dranken, mooie wijnen en
              stijlvolle vintage kleding. Hier draait alles om kwaliteit, smaak
              en een unieke ervaring. Of je nu iets speciaals zoekt voor jezelf,
              een cadeau voor een geliefde of gewoon iets nieuws wilt ontdekken,
              je vindt het bij Satiata.
            </p>

            <div className="buttonsBox">
              <a
                href="https://maps.app.goo.gl/jbF4CZwr4GSjtqRHA"
                rel="noreferrer"
                target="_blank"
              >
                <button className="checkout-btn mapsButton">
                  <img src={mapsIconSatiata} alt=""></img>
                  Adres
                </button>
              </a>
              <a
                href="https://www.instagram.com/satiatastore/"
                rel="noreferrer"
                target="_blank"
              >
                <img className="instaLogo" src={instaRed} alt=""></img>
              </a>
            </div>
          </div>
        </div>

        <ImagesliderSatiataStore />
      </div>
   
    </>
  );
}

export default SatiataStore;
