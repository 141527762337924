import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import "./styling/restraunt.css";
import './styling/restraunt-s.css';
import pdfMenuPulcinos from "../pdf/latest_menu_pulcinos.pdf";
import limoncello from "./assets/limoncello.jpg";
import valenti from "./assets/valenti.jpg";
import gin from "./assets/gin.jpg";
import yuzu from "./assets/yuzu.jpg";
import lemon from "./assets/lemon.png";
import glassBg from "./assets/glass-bg.jpg";
import pdfMenuSatiata from "../pdf/latest_menu_satiata.pdf";
import logoTrans from "./assets/logoTrans.png";
import yuzuu from "./assets/yuzuu.webp";

function Liquors() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 1200px)").matches
  );

  const handleOpenPdf = (restaurant) => {
    if (restaurant == "pulcinos") {
      if (pdfMenuPulcinos) {
        window.open(pdfMenuPulcinos, "_blank");
      } else {
        alert("Er is iets mis gegaan met het openen van het Menu...");
      }
    } else if (restaurant == "satiata") {
      if (pdfMenuSatiata) {
        window.open(pdfMenuSatiata, "_blank");
      } else {
        alert("Er is iets mis gegaan met het openen van het Menu...");
      }
    }
  };

  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <>

    {!isMobile && (
    <div className="desktopNavSpacer"></div>
     )}
      
      <div id="liquorBox">

        
        <div id="liquorBoxLogoWrapper">
          <img src={logoTrans} alt=""></img>
        </div>

        <div id="liquorBoxTextWrapper">
          <h1>
            Pulcinos Liquor Co<span className="yellow">.</span>
          </h1>

          <p>
            Bij <strong>Pulcinos Liquor Co.</strong> draait alles om
            vakmanschap, traditie en innovatie. Onze passie voor het creëren van
            verfijnde likeuren vindt zijn oorsprong in eeuwenoude
            familierecepten en wordt gecombineerd met een eigentijdse benadering
            van smaakontwikkeling. Elke fles die we produceren, is een eerbetoon
            aan de rijke tradities van ambachtelijke dranken, waarbij we alleen
            de beste, natuurlijke ingrediënten gebruiken om unieke en
            onvergetelijke smaken te creëren.
          </p>

          <p>
            Met oog voor detail en een liefde voor kwaliteit, streven we ernaar
            om likeuren te maken die zowel klassiek als vernieuwend zijn, en die
            geschikt zijn voor elke gelegenheid, of het nu een intiem diner is,
            een feestelijke borrel, of een moment van pure verwennerij.
          </p>
        </div>

        <div id="ourBox">
        <div className="craftmanOverlay"></div>
        <img src={yuzuu} alt=""></img>
        <h2>Onze likeuren:</h2>
        </div>

        <div className="liquorShowBox">
          <div data-aos="zoom-in-left" className="liquorShowElement">
            <div className="liqourShowImage">
              <img src={limoncello}></img>
            </div>

            <div className="liqourShowInfo">
              <h3>Pulcinos Limoncello</h3>

              <p>
                Onze <strong>Limoncello</strong> is een klassieker met een
                verhaal. Gemaakt volgens een authentiek Siciliaans
                familierecept, gebruiken we alleen de beste biologische
                citroenen voor een intense en verfrissende citrussmaak.
              </p>
              <p>
                De citroenschillen worden zorgvuldig geweekt om al hun
                essentiële oliën vrij te geven, waardoor de limoncello een pure
                en heldere smaak krijgt, perfect als digestief na een maaltijd
                of als frisse toevoeging in cocktails. De smaak van de zon in
                een fles, rechtstreeks uit het hart van Italië naar jouw glas.
              </p>

              <div className="liqourButtonWrapper">
              <Link
                  className="hightlightsLink"
                  to="/webshop/limoncello"
                  style={{ textDecoration: "none" }}
                >
                <button className="checkout-btn">Bekijk in Webshop</button>
                </Link>
                {/* <button className="checkout-btn">Receptenboek</button> */}
              </div>
            </div>
          </div>

          <div className="liqourImageWrapper">
            <img src={lemon}></img>
          </div>

          <div
            data-aos="zoom-in-right"
            className="liquorShowElement reverseElement"
          >
            <div className="liqourShowImage">
              <img src={valenti}></img>
            </div>

            <div className="liqourShowInfo">
              <h3>Pulcinos Valenti</h3>

              <p>
                Onze <strong>Valenti</strong> sinaasappellikeur is een complexe
                en aromatische likeur die je zintuigen prikkelt. Gemaakt van
                biologische sinaasappels, combineren we de frisse citrus met
                subtiele tonen van kaneel, vanille en Jamaicaanse peper.
              </p>
              <p>
                Deze perfect uitgebalanceerde combinatie van smaken zorgt voor
                een unieke, warme afdronk met een lichte kruidigheid. De Valenti
                is heerlijk puur, maar ook een ideale toevoeging aan cocktails
                en desserts voor een extra dimensie van smaak.
              </p>

              <div className="liqourButtonWrapper">
              <Link
                  className="hightlightsLink"
                  to="/webshop/valenti"
                  style={{ textDecoration: "none" }}
                >
                <button className="checkout-btn">Bekijk in Webshop</button>
                </Link>
                {/* <button className="checkout-btn">Receptenboek</button> */}
              </div>
            </div>
          </div>

          <div className="liqourImageWrapper">
            <img src={lemon}></img>
          </div>

          <div data-aos="zoom-in-left" className="liquorShowElement">
            <div className="liqourShowImage">
              <img src={yuzu}></img>
            </div>

            <div className="liqourShowInfo">
              <h3>Pulcinos Yuzu Likeur</h3>

              <p>
                Met onze <strong>Yuzu Likeur</strong> brengen we de frisheid en
                exotische flair van Japan naar jouw glas. Gemaakt van verse
                yuzu, een zeldzame Japanse citrusvrucht, biedt deze likeur een
                prachtige balans van zure en zoete tonen met een aromatische
                geur.
              </p>
              <p>
                De Yuzu Likeur is licht, verfijnd en vol van smaak, perfect voor
                liefhebbers van unieke citrusdranken en ideaal voor gebruik in
                moderne cocktails. Deze likeur voegt een verfrissende twist toe
                aan elke drank of gerecht.
              </p>

              <div className="liqourButtonWrapper">
              <Link
                  className="hightlightsLink"
                  to="/webshop/yuzu"
                  style={{ textDecoration: "none" }}
                >
                <button className="checkout-btn">Bekijk in Webshop</button>
                </Link>
                {/* <button className="checkout-btn">Receptenboek</button> */}
              </div>
            </div>
          </div>

          <div className="liqourImageWrapper">
            <img src={lemon}></img>
          </div>

          <div
            data-aos="zoom-in-right"
            className="liquorShowElement reverseElement"
          >
            <div className="liqourShowImage">
              <img src={gin}></img>
            </div>

            <div className="liqourShowInfo">
              <h3>Pulcinos Yuzu Gin</h3>

              <p>
                Naast onze likeuren presenteren we trots onze{" "}
                <strong>Yuzu Gin</strong>. Deze premium gin is doordrenkt met
                verse yuzu voor een verkwikkende citrusexplosie. De delicate
                balans tussen de jeneverbes en yuzu maakt deze gin zowel
                verfrissend als complex, ideaal voor een G&T of andere creatieve
                cocktails
              </p>
              <p>
                Onze Yuzu Gin is een favoriet voor iedereen die houdt van een
                gin met een exotische, frisse en subtiele smaak.
              </p>

              <div className="liqourButtonWrapper">
              <Link
                  className="hightlightsLink"
                  to="/webshop/yuzugin"
                  style={{ textDecoration: "none" }}
                >
                <button className="checkout-btn">Bekijk in Webshop</button>
                </Link>
                {/* <button className="checkout-btn">Receptenboek</button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="craftmanWrapper">
        <div className="craftmanOverlay"></div>
        <img src={glassBg} alt=""></img>

        <div className="craftmanTextWrapper">
          <h4 className="yellow">Vakmanschap en Kwaliteit</h4>

          <p>
            Bij <strong className="yellow">Pulcinos Liquor Co.</strong> geloven
            we dat een likeur niet alleen een drank is, maar een ervaring. Van
            de selectie van de beste biologische en natuurlijke ingrediënten tot
            het zorgvuldige distillatieproces en de rijping, elke stap in het
            productieproces wordt met de grootste zorg uitgevoerd. Ons doel is
            om dranken te creëren die net zo verfijnd zijn als de momenten
            waarop ze worden genoten.
          </p>

          <div id="craftmanLogoWrapper">
            <img src={logoTrans} alt=""></img>
          </div>
        </div>
      </div>

     
    </>
  );
}

export default Liquors;
