// NavContext.js
import React, { createContext, useState } from 'react';

export const NavContext = createContext();

export const NavProvider = ({ children }) => {
  
    const [isMobile, setIsMobile] = useState(
        window.matchMedia("(max-width: 1200px)").matches
      );
  const [showMobileNav, setShowMobileNav] = useState(false);
  const [instaScroll, setInstaScroll] = useState(true);
  const [mobileNavCollapsed, setMobileNavCollapsed] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  const handleLinkClick = (isHome) => {

    if (isMobile) {
      setShowMobileNav(false);
      setInstaScroll(false);
    }

    if (isHome) {
      setMobileNavCollapsed(false);
    }

    document.documentElement.style.overflow = 'auto';

    // window.scrollBy({ top: -100, behavior: 'smooth' });

    setTimeout(() => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      const spacerHeight = window.innerHeight * 0.2;
      const mobileSpacerHeight = 100;

      if (!isMobile) {
        if (scrollTop >= spacerHeight) {
          setIsVisible(false);
        }
      } else {
        if (scrollTop >= mobileSpacerHeight) {
          if (!isHome) {
            setMobileNavCollapsed(true);
          }
        }
      }
    }, 1500);
  };

  return (
    <NavContext.Provider
      value={{
        isMobile,
        setIsMobile,
        showMobileNav,
        setShowMobileNav,
        instaScroll,
        setInstaScroll,
        mobileNavCollapsed,
        setMobileNavCollapsed,
        isVisible,
        setIsVisible,
        handleLinkClick,
      }}
    >
      {children}
    </NavContext.Provider>
  );
};
