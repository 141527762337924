import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { CartContext } from "../util/cartContext";
import { AgeVerificationContext } from "../util/AgeVerificationContext";
import "./styling/productPage.css";
import "./styling/productPage-s.css";
import menuLogo from "./assets/logoTrans.png";
import lemonSlice from "./assets/lemonSlice2.webp";
import lemonSlice1 from "./assets/lemonSlice.webp";
import branchRight1 from "./assets/branchRight1.webp";
import branchRight2 from "./assets/branchRight2.webp";
import branchLeftCorner from "./assets/branchLeftCorner.webp";
import branchLeft1 from "./assets/branchLeft1.webp";
import limoncello from "./assets/limoncello.jpg";
import valenti from "./assets/valenti.jpg";
import yuzu from "./assets/yuzu.jpg";
import valentiCoctail from "./assets/valentiCoctail.png";
import yuzuGin from "./assets/gin.jpg";
import cartImg from "./assets/cart.svg";

const Webshop = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { cart, addToCart, removeFromCart, totalPrice } =
    useContext(CartContext);

  const { isOver18, setIsOver18 } = useContext(AgeVerificationContext);
  
  // useEffect(() => {
    
  //   if(isOver18){
  //     document.documentElement.style.overflow = 'auto';
  //   } else {
  //     document.documentElement.style.overflow = 'hidden';
  //   }

  // }, []);

  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 1200px)").matches
  );

  const [quantities, setQuantities] = useState(Array(5).fill(1)); // Initialize with 1 for each product

  const products = [
    { id: 1, name: "Pulcinos Limoncello", price: 17.95 },
    { id: 2, name: "Pulcinos Valenti", price: 18.95 },
    { id: 3, name: "Pulcinos Yuzu", price: 26.5 },
    { id: 4, name: "Pulcinos Yuzu Gin", price: 34.5 },
    { id: 5, name: "Valenti X San Pellegrino cadeaupakket", price: 20.95 },
  ];

  const addProductToCart = (product, amount) => {
    addToCart(product, amount);
  };

  const handleQuantityChange = (index, value) => {
    const newQuantities = [...quantities];
    newQuantities[index] = value < 1 ? 1 : value;
    setQuantities(newQuantities);
  };

  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);


  return (
    <>
      {!isOver18 && (
        <div id="ageWrapper">
          <div id="ageBox">

            <div id="ageLogoWrapper">
              <img src={menuLogo} alt=""></img>
            </div>

            <h1>Ben je 18 jaar of ouder?</h1>

            <div id="ageButtons">

              <button className="checkout-btn yes-btn" onClick={() => setIsOver18(true)}>Ja</button>
              <Link
                to="/"
              >
              <button className="checkout-btn">Nee</button>
              </Link>

            </div>
          </div>
        </div>
      )}
      {!isMobile && <div className="desktopNavSpacer"></div>}
      <div>
        <div id="productBox">
          <div id="productBoxWrapper">
            <img
              className="productBoxBackgroundImage"
              src={branchRight1}
              alt="background image"
            ></img>
            <img
              className="productBoxBackgroundImage"
              src={branchRight2}
              alt="background image"
            ></img>
            <img
              className="productBoxBackgroundImage"
              src={branchLeft1}
              alt="background image"
            ></img>
            <img
              className="productBoxBackgroundImage"
              src={branchLeftCorner}
              alt="background image"
            ></img>

            <img
              className="productBoxBackgroundImage"
              src={lemonSlice}
              alt="lemon slice"
            ></img>
            <img
              className="productBoxBackgroundImage"
              src={lemonSlice1}
              alt="lemon slice"
            ></img>

            <div className="productBoxTitle">
              <img src={menuLogo} alt="Pulcinos Logo"></img>

              <h1>Liquori</h1>
              <span>Likeuren</span>
            </div>

            <div id="productContainer">
              <div data-aos="fade-up" className="product">
                <div className="productImageWrapper">
                  <img src={limoncello} alt="Pulcinos Limoncello"></img>
                </div>

                <div className="productInfo">
                  {!isMobile ? (
                    <h2 className="productTitle">
                      <span className="yellow">Pulcinos Limoncello</span> -
                      17,95
                    </h2>
                  ) : (
                    <h2 className="productTitle">
                      <span className="yellow">Pulcinos Limoncello&nbsp;</span>
                      <span>17,95</span>{" "}
                    </h2>
                  )}

                  <span className="productInfoSpan">
                    Limoncello gemaakt volgens traditioneel familierecept met
                    biologische en met de hand geschilde citroenen.
                  </span>

                  <div className="productButtons">
                    <Link
                      className="hightlightsLink"
                      to="/webshop/limoncello"
                      style={{ textDecoration: "none" }}
                    >
                      {!isMobile ? (
                        <button>Bekijk product</button>
                      ) : (
                        <button>Bekijk</button>
                      )}
                    </Link>
                    <input
                      type="number"
                      id="quantity"
                      value={quantities[0]}
                      name="quantityLimoncello"
                      onChange={(e) =>
                        handleQuantityChange(0, parseInt(e.target.value, 10))
                      }
                    />
                    <button
                      className="add-to-cart"
                      onClick={() =>
                        addProductToCart(products[0], quantities[0])
                      }
                    >
                      <img src={cartImg} alt="+"></img>
                    </button>
                  </div>
                </div>
                {/* <button onClick={() => addProductToCart(products[0], 1)}>
                    Receptenboek
                  </button> */}
              </div>
              <div data-aos="fade-up" className="product">
                <div className="productImageWrapper">
                  <img src={valenti} alt="Pulcinos Valenti"></img>
                </div>

                <div className="productInfo">
                  {!isMobile ? (
                    <h2 className="productTitle">
                      <span className="yellow">Pulcinos Valenti</span> - 18,95
                    </h2>
                  ) : (
                    <h2 className="productTitle">
                      <span className="yellow">Pulcinos Valenti&nbsp;</span>
                      <span>18,95</span>
                    </h2>
                  )}

                  <span className="productInfoSpan">
                    Onze Valenti is een sinaasappellikeur waaraan pepers uit
                    Jamaica, vanille uit Madagaskar en kaneel uit Ceylon zijn
                    toegevoegd. Een rijke kruidige smaak, gecombineerd met zoet
                    van de Valenciaanse sinaasappels; een perfecte balans.
                  </span>

                  <div className="productButtons">
                    <Link
                      className="hightlightsLink"
                      to="/webshop/valenti"
                      style={{ textDecoration: "none" }}
                    >
                      {!isMobile ? (
                        <button>Bekijk product</button>
                      ) : (
                        <button>Bekijk</button>
                      )}
                    </Link>

                    <input
                      type="number"
                      id="quantity"
                      value={quantities[1]}
                      name="quantityValenti"
                      onChange={(e) =>
                        handleQuantityChange(1, parseInt(e.target.value, 10))
                      }
                    />
                    <button
                      className="add-to-cart"
                      onClick={() =>
                        addProductToCart(products[1], quantities[1])
                      }
                    >
                      <img src={cartImg} alt="+"></img>
                    </button>
                  </div>
                </div>
              </div>

              <div data-aos="fade-up" className="product">
                <div className="productImageWrapper">
                  <img src={yuzu} alt="Pulcinos Yuzu"></img>
                </div>

                <div className="productInfo">
                  {!isMobile ? (
                    <h2 className="productTitle">
                      <span className="yellow">Pulcinos Yuzu</span> - 26,50
                    </h2>
                  ) : (
                    <h2 className="productTitle">
                      <span className="yellow">Pulcinos Yuzu&nbsp;</span>
                      <span>26,50</span>
                    </h2>
                  )}

                  <span className="productInfoSpan">
                    Onze Yuzu likeur is biologisch en gemaakt van verse Yuzu,
                    een citrusvrucht uit Japan. De smaak is een zachte mix van
                    citroen, mandarijn en sinaasappel. Perfect om te mixen met
                    tonic of prosecco!
                  </span>

                  <div className="productButtons">
                    <Link
                      className="hightlightsLink"
                      to="/webshop/yuzu"
                      style={{ textDecoration: "none" }}
                    >
                      {!isMobile ? (
                        <button>Bekijk product</button>
                      ) : (
                        <button>Bekijk</button>
                      )}
                    </Link>

                    <input
                      type="number"
                      id="quantity"
                      value={quantities[2]}
                      name="quantityYuzu"
                      onChange={(e) =>
                        handleQuantityChange(2, parseInt(e.target.value, 10))
                      }
                    />
                    <button
                      className="add-to-cart"
                      onClick={() =>
                        addProductToCart(products[2], quantities[2])
                      }
                    >
                      <img src={cartImg} alt="+"></img>
                    </button>
                  </div>
                </div>
              </div>

              <div data-aos="fade-up" className="product">
                <div className="productImageWrapper">
                  <img src={yuzuGin} alt="Pulcinos Yuzu Gin"></img>
                </div>

                <div className="productInfo">
                  {!isMobile ? (
                    <h2 className="productTitle">
                      <span className="yellow">Pulcinos Yuzu Gin</span> - 34,50
                    </h2>
                  ) : (
                    <h2 className="productTitle">
                      <span className="yellow">Pulcinos Yuzu Gin&nbsp;</span>
                      <span>34,50</span>
                    </h2>
                  )}

                  <span className="productInfoSpan">
                    Organic, Dutch Yuzu Gin. Gemaakt met verse Yuzu schil &
                    jeneverbes met hinten van tijm & citroen. Nu verkrijgbaar
                    bij @satiatatilburg en in ons restaurant. Probeer snel een
                    van onze citrus Gin – Tonics!
                  </span>

                  <div className="productButtons">
                    <Link
                      className="hightlightsLink"
                      to="/webshop/yuzugin"
                      style={{ textDecoration: "none" }}
                    >
                      {!isMobile ? (
                        <button>Bekijk product</button>
                      ) : (
                        <button>Bekijk</button>
                      )}
                    </Link>

                    <input
                      type="number"
                      id="quantity"
                      value={quantities[3]}
                      name="quantityGin"
                      onChange={(e) =>
                        handleQuantityChange(3, parseInt(e.target.value, 10))
                      }
                    />
                    <button
                      className="add-to-cart"
                      onClick={() =>
                        addProductToCart(products[3], quantities[3])
                      }
                    >
                      <img src={cartImg} alt="+"></img>
                    </button>
                  </div>
                </div>
              </div>

              <div data-aos="fade-up" className="product">
                <div className="productImageWrapper">
                  <img
                    src={valentiCoctail}
                    alt="Pulcinos Valenti Coctail"
                  ></img>
                </div>

                <div className="productInfo">
                  {!isMobile ? (
                    <h2 className="productTitle">
                      <span className="yellow">
                        Valenti X San Pellegrino cadeaupakket
                      </span>{" "}
                      - 20,95
                    </h2>
                  ) : (
                    <h2 className="productTitle">
                      <span className="yellow">
                        Valenti X San Pellegrino cadeaupakket&nbsp;
                      </span>
                      <span>20,95</span>
                    </h2>
                  )}

                  <span className="productInfoSpan">
                    Een cocktail pakket van Pulcino’s X San Pellegrino.
                    <br></br>
                    1x Valenti (arancello) 200ml
                    <br></br>
                    1x San Pellegrino ginger beer
                    <br></br>
                    1x Cocktailglas
                  </span>

                  <div className="productButtons">
                    <Link
                      className="hightlightsLink"
                      to="/webshop/sanpellegrino"
                      style={{ textDecoration: "none" }}
                    >
                      {!isMobile ? (
                        <button>Bekijk product</button>
                      ) : (
                        <button>Bekijk</button>
                      )}
                    </Link>

                    <input
                      type="number"
                      id="quantity"
                      value={quantities[4]}
                      name="quantityPellegrino"
                      onChange={(e) =>
                        handleQuantityChange(4, parseInt(e.target.value, 10))
                      }
                    />
                    <button
                      className="add-to-cart"
                      onClick={() =>
                        addProductToCart(products[4], quantities[4])
                      }
                    >
                      <img src={cartImg} alt="+"></img>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Webshop;
