import React, {useState} from 'react';
import './styling/admin.css';
import { Link } from 'react-router-dom';

function Admin() {

  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 1200px)").matches
  );

  return (
    <>
    {!isMobile && (
      <div className="desktopNavSpacer"></div>
       )}
    <div id='adminBox'>
      <h2>Welkom op de Administrator Pagina</h2>

      <div id='adminLinks'>

        <Link to="/admin/setmenu/pulcinos" ><button className='btn-download'>Bewerk Menukaart Pulcinos</button></Link>
        <br></br>
        <Link to="/admin/setmenu/satiata" ><button className='btn-download'>Bewerk Menukaart Satiata</button></Link>
        <br></br>
        <Link to="/admin/orders" ><button className='btn-download'>Beheer Bestellingen</button></Link>
        <br></br>
        <Link to="/admin/shipping" ><button className='btn-download'>Verzenden</button></Link>
        <br></br>
        <Link to="/admin/analytics" ><button className='btn-download'>Statestieken</button></Link>

      </div>
    </div>
    </>
  );
}

export default Admin;
