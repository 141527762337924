// Functions to interact with the PDFMenu table

export async function getPdfById(restaurant, pdfId) {
    try {
      const response = await fetch(`https://jouwklussenbedrijf.nl/dataservice/pdf/${restaurant}/${pdfId}`);
      //const response = await fetch(`http://localhost:5009/pdf/${restaurant}/${pdfId}`);
      if (response.ok) {
        const pdf = await response.json();
        return pdf;
      } else {
        console.error('Failed to fetch PDF');
        return false;
      }
    } catch (error) {
      console.error('Error fetching PDF:', error);
      return false;
    }
  }
  
  export async function getAllPdfs(restaurant) {
    try {
      console.log("Sending request...");
      const response = await fetch(`https://jouwklussenbedrijf.nl/dataservice/pdf/${restaurant}`);
      //const response = await fetch(`http://localhost:5009/pdf/${restaurant}`);
      if (response.ok) {
        console.log("Response ok", response);
        const pdfs = await response.json();
        console.log("Pdfs extracted");
        return pdfs;
      } else {
        console.log("Response error");
        console.error('Failed to fetch PDFs');
        return false;
      }
    } catch (error) {
      console.log("Error fetching data...");
      console.error('Error fetching PDFs:', error);
      return false;
    }
  }
  
  export async function addPdf(restaurant, formData) {
    try {
      console.log("PAssed rest: ", restaurant);
      const response = await fetch(`https://jouwklussenbedrijf.nl/dataservice/pdf/${restaurant}/upload`, {
      //const response = await fetch(`http://localhost:5009/pdf/${restaurant}/upload`, {
        method: 'POST',
        body: formData,
      });
  
      if (response.ok) {
        const newPdf = await response.json();
        return newPdf;
      } else {
        console.error('Failed to add PDF');
        console.log(await response.json());
        return false;
      }
    } catch (error) {
      console.error('Error adding PDF:', error);
      return false;
    }
  }

  export async function updatePdf(restaurant, pdfId, data) {
    try {
      const response = await fetch(`https://jouwklussenbedrijf.nl/dataservice/pdf/${restaurant}/${pdfId}`, {
      //const response = await fetch(`http://localhost:5009/pdf/${restaurant}/${pdfId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        return true;
      } else {
        console.error('Failed to update PDF');
        return false;
      }
    } catch (error) {
      console.error('Error updating PDF:', error);
      return false;
    }
  }
  
  export async function deletePdf(restaurant, pdfId) {
    try {
      const response = await fetch(`https://jouwklussenbedrijf.nl/dataservice/pdf/${restaurant}/${pdfId}`, {
      //const response = await fetch(`http://localhost:5009/pdf/${restaurant}/${pdfId}`, {
        method: 'DELETE',
      });
      if (response.ok) {
        return true;
      } else {
        console.error('Failed to delete PDF');
        return false;
      }
    } catch (error) {
      console.error('Error deleting PDF:', error);
      return false;
    }
  }
  
  export async function setPdfActive(restaurant, pdfId) {
    try {
      const response = await fetch(`https://jouwklussenbedrijf.nl/dataservice/pdf/${restaurant}/${pdfId}/set_active`, {
      //const response = await fetch(`http://localhost:5009/pdf/${restaurant}/${pdfId}/set_active`, {
        method: 'PUT',
      });
      if (response.ok) {
        return true;
      } else {
        console.error('Failed to update PDF active status');
        return false;
      }
    } catch (error) {
      console.error('Error updating PDF active status:', error);
      return false;
    }
  }

  export async function getAllPdfsMetadata(restaurant) {
    try {
      const response = await fetch(`https://jouwklussenbedrijf.nl/dataservice/pdf/${restaurant}/metadata`);
      //const response = await fetch(`http://localhost:5009/pdf/${restaurant}/metadata`);
      if (response.ok) {
        const pdfs = await response.json();
        return pdfs;
      } else {
        console.error('Failed to fetch PDF metadata');
        return false;
      }
    } catch (error) {
      console.error('Error fetching PDF metadata:', error);
      return false;
    }
}

export async function getPdfData(restaurant, pdfId) {
    try {
      const response = await fetch(`https://jouwklussenbedrijf.nl/dataservice/pdf/${restaurant}/${pdfId}/data`);
      //const response = await fetch(`http://localhost:5009/pdf/${restaurant}/${pdfId}/data`);
      if (response.ok) {
        const pdfData = await response.json();
        return pdfData;
      } else {
        console.error('Failed to fetch PDF data');
        return false;
      }
    } catch (error) {
      console.error('Error fetching PDF data:', error);
      return false;
    }
}