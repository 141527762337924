import React, { useState, useEffect } from 'react';
import { isAuthenticated } from "../util/protectedRoute";
import { Link, useNavigate } from "react-router-dom";
import './styling/login.css';

const Login = () => {

  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 1200px)").matches
  );

  useEffect(() => {

    const is_Authenticated = async () => {
      var userAuthenticated = false;
      const token = localStorage.getItem("Token");

      if (token) {
        try {
          const decodedToken = JSON.parse(atob(token.split(".")[1])); // Decode token
          var decodedUserId = decodedToken.sub;

          userAuthenticated = await isAuthenticated(decodedUserId);

          console.log(userAuthenticated);

        } catch {
          console.log("Token not in right format...");
        }
      }

      if (userAuthenticated == true){
        navigate(`/user/${decodedUserId}`)        
      }
    };

  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
      
      //const response = await fetch('http://127.0.0.1:5009/login', {
      const response = await fetch('https://jouwklussenbedrijf.nl/dataservice/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username,
        password,
      }),
    });
  
    if (response.ok) {
      // Login succesvol, verwerk de response
      const data = await response.json();
      localStorage.setItem('Token', data.Token); // Als je een token terugkrijgt
      const decodedToken = JSON.parse(atob(data.Token.split('.')[1])); // Decode token
      const idFromToken = decodedToken.sub;
      window.location.href=`/`;
    } else {
      // Foutafhandeling
      console.error('Login failed');
    }

  };

  return (

    <>
    {!isMobile && (
      <div className="desktopNavSpacer"></div>
       )}

    <div className="login-wrapper">
    <div className="login-box">
      <h2>Admin Login</h2>
      <form onSubmit={handleSubmit}>
        <input 
          type="text" 
          placeholder="Username" 
          value={username} 
          onChange={(e) => setUsername(e.target.value)} 
        />
        <input 
          type="password" 
          placeholder="Password" 
          value={password} 
          onChange={(e) => setPassword(e.target.value)} 
        />
        <button type="submit" className="btn btn-pulci-green">Login</button>
      </form>
      {error && <p>{error}</p>}
    </div>
  </div>
  </>
  );
};

export default Login;
