// src/components/Cart.js
import React, { useContext, useEffect, useState } from 'react';
import { CartContext } from '../util/cartContext';
import './styling/cart.css';
import { Link } from "react-router-dom";
import limoncello from "../pages/assets/limoncello.jpg";
import valenti from "../pages/assets/valenti.jpg";
import yuzu from "../pages/assets/yuzu.jpg";
import valentiCoctail from "../pages/assets/valentiCoctail.png";
import yuzuGin from "../pages/assets/gin.jpg";

const CartBasic = ({shippingCost, totalOrderPrice}) => { 

  const { cart, addToCart, removeFromCart, clearCart, totalPrice } = useContext(CartContext);

  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 1000px)").matches
  );

  const getProductImage = (productName) => {
    switch (productName) {
      case 'Pulcinos Limoncello':
        return limoncello;
      case 'Pulcinos Valenti':
        return valenti;
      case 'Pulcinos Yuzu':
        return yuzu;
      case 'Pulcinos Yuzu Gin':
        return yuzuGin;
      case 'Valenti X San Pellegrino cadeaupakket':
        return valentiCoctail;
      default:
        return null; // Add a default image if necessary
    }
  };

  
  
  return (
    <div className='cart-basic-items'>
      <h2>Overzicht</h2>
      {cart.length === 0 ? (
        <p>Het winkelwagentje is leeg</p>
      ) : (
        <ul >
        {
          cart.map(item => (
            <li key={item.id}>
            <div className="item-info">
              <div className='item-info-imageWrapper'>
              <img src={getProductImage(item.name)} alt={item.name} className="product-image" />
              </div>
              <div className='item-info-text'>
                {!isMobile ? ( <h3>{item.name} <span className='white'>({item.quantity})</span></h3>) : 
                (<h3>{item.name}</h3>)}               
                <p>({item.quantity}) Prijs: €{(item.price * item.quantity).toFixed(2)}</p>
              </div>
            </div>            
          </li>
          ))}
        </ul>
      )}

      <Link to="/cart"><p className='btn-bewerk'>Bewerk bestelling</p></Link>
     
      <div className='priceDetails'>
        <div>
        <h3>Producten ({cart.length}) </h3>
        <h3>€ {totalPrice.toFixed(2)}</h3>
        </div>
        <div>
        <h3>Verzendkosten </h3>
        <h3>€ {shippingCost.toFixed(2)}</h3>
        </div>
        <div>
        <h3>Totaal </h3>
        <h3>€ {(totalOrderPrice).toFixed(2)}</h3>
        </div>
        
      </div>
    </div>
  );
};

export default CartBasic;
