import React, { useContext, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { addOrder } from "../dataSource/OrderSource";
import PaymentMethodSelector from "./PaymentMethodSelector";
import ShippingOptions from "./ShippingOptions";
import CartBasic from "./CartBasic";
import { CartContext } from "../util/cartContext";
import "./styling/details.css";
import "./styling/details-s.css";
import arrow from "../pages/product pages/assets/arrow-back.svg";
import { ClipLoader } from 'react-spinners';

const OrderDetails = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [differentShippingAddress, setDifferentShippingAddress] = useState(false);
  const [addressComplete, setAddressComplete] = useState(false);
  const [paymentMethodComplete, setPaymentMethodComplete] = useState(false);
  const [shippingComplete, setShippingComplete] = useState(false);
  const [confirmedAge, setConfirmedAge] = useState(false);
  const [shippingOption, setShippingOption] = useState([]);

  const [shippingCost, setShippingCost] = useState(5.95);
  const [totalOrderPrice, setTotalOrderPrice] = useState(0.00);

  const canPlaceOrder = addressComplete && paymentMethodComplete && confirmedAge && shippingComplete;

  // Order variables
  const [firstName, setFirstName] = useState("Bart");
  const [preposition, setPreposition] = useState("van");
  const [lastName, setLastName] = useState("Dijk");
  const [phoneNumber, setPhoneNumber] = useState("1234567890");
  const [emailAddress, setEmailAddress] = useState("prod.goldweb@gmail.com");
  const [streetName, setStreetName] = useState("Molengraaffplantsoen");
  const [houseNumber, setHouseNumber] = useState("74");
  const [houseNumberAddition, setHouseNumberAddition] = useState("A");
  const [zipcode, setZipcode] = useState("3571ZZ");
  const [country, setCountry] = useState("NL");
  const [note, setNote] = useState("");
  const [city, setCity] = useState("Utrecht");
  const [companyName, setCompanyName] = useState("Example Company");
  const [statusMessage, setStatusMessage] = useState("");

  const [shippingFirstName, setShippingFirstName] = useState("Bart");
  const [shippingPreposition, setShippingPreposition] = useState("van");
  const [shippingLastName, setShippingLastName] = useState("Dijk");
  const [shippingStreetName, setShippingStreetName] = useState("Molengraaffplantsoen");
  const [shippingHouseNumber, setShippingHouseNumber] = useState("74");
  const [shippingHouseNumberAddition, setShippingHouseNumberAddition] = useState("A");
  const [shippingZipcode, setShippingZipcode] = useState("3571ZZ");
  const [shippingCountry, setShippingCountry] = useState("NL");
  const [shippingCity, setShippingCity] = useState("Utrecht");
  const [shippingCompanyName, setShippingCompanyName] = useState("Example Company");

  const [isOrdering, setIsOrdering] = useState(false);
  const [orderPrice, setOrderPrice] = useState(""); // Added
  const [orderLines, setOrderLines] = useState([]); // Added
  const [status, setStatus] = useState(""); // Added
  const [paymentStatus, setPaymentStatus] = useState(""); // Added
  const [paymentId, setPaymentId] = useState(""); // Added
  const [error, setError] = useState("");

  const navigate = useNavigate();

  const { cart, addToCart, removeFromCart, clearCart, totalPrice } = useContext(CartContext);
  const [selectedPaymentMethod, setSelectedMethod] = useState('');

  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 1200px)").matches
  );


  useEffect(() => {
    
    setTotalOrderPrice(totalPrice + shippingCost)

  }, [totalPrice, shippingCost]);


   const handleSubmitDetails = async (e) => {
    e.preventDefault();
    setAddressComplete(true);
  };

  // Handle the change event to update the state
  const handleDifferentAddressCheckboxChange = (event) => {
    setDifferentShippingAddress(event.target.checked);
  };
  
  const handleAgeCheckboxChange = (event) => {
    setConfirmedAge(event.target.checked);
  };

  useEffect(() => {
    setShippingComplete(true);

    if (shippingOption === "winkel"){
      setShippingCost(0.00)
    } else {
      setShippingCost(5.95)
    }

  }, [shippingOption]);

  useEffect(() => {

    if(selectedPaymentMethod != ''){
    setPaymentMethodComplete(true);
    } else {
      setPaymentMethodComplete(false);
    }
    
  }, [selectedPaymentMethod]);


  const generateShippingData = () => {

    let weight = cart.length * 1200;
    
    let shippingData = {};

    try {

      if (shippingOption.DeliveryDate && shippingOption.Timeframe) {

        // This is a delivery
        shippingData.delivery_options = 'Daytime';
        shippingData.weight = weight;  
        shippingData.product_code_delivery = '3449';
        shippingData.delivery_date = shippingOption.DeliveryDate;
        shippingData.location_code = '';

      } else if (shippingOption.address && shippingOption.location_code) {

          shippingData.delivery_options = 'Pickup';
          shippingData.weight = weight;  
          shippingData.product_code_delivery = '3574';
          shippingData.delivery_date = '';        
          shippingData.location_code = shippingOption.location_code;

      } else {
          throw new Error("Invalid shipping option data");
      }
      
    } catch (error) {
      console.log("Error extracting shipping Data: ", error);
    } 

    return shippingData;
};
  

  // const placeOrder = async () => {

  //   setIsOrdering(true);

  //   try {
    
  //   let addressData = {};
  //   let shippingAddressData = {};
  //   let shippingData = {};
  //   let customerData = {};
  //   let orderLines = {};


  //   try {

  //     addressData = {         
  //       shippingAddress: differentShippingAddress ? 0 : 1,
  //       streetName: streetName,
  //       houseNumber: houseNumber,
  //       houseNumberAddition: houseNumberAddition,
  //       zipcode: zipcode,
  //       city: city,
  //       country: country
  //     };

  //   if(differentShippingAddress){
  //     shippingAddressData = {      
  //       shippingAddress: 1,
  //       streetName: shippingStreetName,
  //       houseNumber: shippingHouseNumber,
  //       houseNumberAddition: shippingHouseNumberAddition,
  //       zipcode: shippingZipcode,
  //       city: shippingCity,
  //       country: shippingCountry,
  //       firstName: shippingFirstName,
  //       preposition: shippingPreposition,
  //       lastName: shippingLastName,
  //       companyName: shippingCompanyName
  //   }}
    
  //   // overwrite shippingaddress data if pickuppoint is selected
  //   if(shippingOption.address && shippingOption.location_code){
  //     shippingAddressData = {      
  //       shippingAddress: 1,
  //       streetName: shippingOption.address.street,
  //       houseNumber: shippingOption.address.house_nr,
  //       houseNumberAddition: '',
  //       zipcode: shippingOption.address.zipcode,
  //       city: shippingOption.address.city,
  //       country: shippingOption.address.countrycode,
  //       firstName: shippingFirstName,
  //       preposition: shippingPreposition,
  //       lastName: shippingLastName,
  //       companyName: shippingOption.name
  //   }
  //     addressData.shippingAddress = 0;
  //   }

  //   shippingData = generateShippingData();
    
  // } catch (error) {

  //   console.log("Error creating data", error);
  //   setIsOrdering(false);
    
  // }

  //   customerData = {
  //     firstName: firstName,
  //     preposition: preposition,
  //     lastName: lastName,
  //     phoneNumber: phoneNumber,
  //     emailAddress: emailAddress,
  //     companyName: companyName
  //   }

  //   orderLines = cart.flatMap(item =>
  //     Array.from({ length: item.quantity }, () => ({ productId: item.id, cost: item.price }))
  //   );
      

  //   setTotalOrderPrice(totalPrice + shippingCost);    

  //   //const redirectUrl = 'http://localhost:3000/thankyou'
  //   const redirectUrl = 'http://goldfishproductions.nl/thankyou'

  //   try {

  //     const response = await addOrder(totalOrderPrice, note, customerData, addressData, shippingAddressData, shippingData, orderLines, selectedPaymentMethod, redirectUrl); 

  //     if (response.payment_url[0]) {
  //       window.location.href = response.payment_url[0];
  //     } else {
  //       console.log("No url back...", response);
  //     }

  //   } catch (error) {
  //     console.log("Error creating order: ", error);
  //     setIsOrdering(false);
  //   }   

  //   setIsOrdering(false);
  //   console.log("Order placed");
      
  // } catch (error) {
  //   console.log("Error placing order");
  //   setIsOrdering(false);
  // } 
  // };

  const placeOrder = async () => {

    const maxRetries = 3; // Number of retry attempts
    const retryDelay = 1000; // Delay between retries in milliseconds
    setIsOrdering(true);
    setStatusMessage("");
  
    try {
      let addressData = {};
      let shippingAddressData = {};
      let shippingData = {};
      let customerData = {};
      let orderLines = {};
  
      // Step 1: Prepare Data
      try {
        addressData = {         
          shippingAddress: differentShippingAddress ? 0 : 1,
          streetName: streetName,
          houseNumber: houseNumber,
          houseNumberAddition: houseNumberAddition,
          zipcode: zipcode,
          city: city,
          country: country
        };
  
        if (differentShippingAddress) {
          shippingAddressData = {      
            shippingAddress: 1,
            streetName: shippingStreetName,
            houseNumber: shippingHouseNumber,
            houseNumberAddition: shippingHouseNumberAddition,
            zipcode: shippingZipcode,
            city: shippingCity,
            country: shippingCountry,
            firstName: shippingFirstName,
            preposition: shippingPreposition,
            lastName: shippingLastName,
            companyName: shippingCompanyName
          };
        }
  
        // Overwrite shipping address data if a pickup point is selected
        if (shippingOption.address && shippingOption.location_code) {
          shippingAddressData = {      
            shippingAddress: 1,
            streetName: shippingOption.address.street,
            houseNumber: shippingOption.address.house_nr,
            houseNumberAddition: '',
            zipcode: shippingOption.address.zipcode,
            city: shippingOption.address.city,
            country: shippingOption.address.countrycode,
            firstName: shippingFirstName,
            preposition: shippingPreposition,
            lastName: shippingLastName,
            companyName: shippingOption.name
          };
          addressData.shippingAddress = 0;
        }
  
        shippingData = generateShippingData();
      } catch (error) {
        console.log("Error creating data", error);
        setIsOrdering(false);
        return; // Stop function if data preparation fails
      }
  
      // Step 2: Set Customer Data and Order Lines
      customerData = {
        firstName: firstName,
        preposition: preposition,
        lastName: lastName,
        phoneNumber: phoneNumber,
        emailAddress: emailAddress,
        companyName: companyName
      };
  
      orderLines = cart.flatMap(item =>
        Array.from({ length: item.quantity }, () => ({ productId: item.id, cost: item.price }))
      );
  
      setTotalOrderPrice(totalPrice + shippingCost);    
  
      // const redirectUrl = 'http://goldfishproductions.nl/thankyou';
      const redirectUrl = 'http://localhost:3000/thankyou';
  
      // Step 3: Attempt to Place Order with Retry Mechanism
      let response;
      for (let attempt = 1; attempt <= maxRetries; attempt++) {
        console.log("Trying, attempt:", attempt);
      
        try {
          response = await addOrder(
            totalOrderPrice, note, customerData, addressData, 
            shippingAddressData, shippingData, orderLines, selectedPaymentMethod, redirectUrl
          );
      
          if (response && response.payment_url && response.payment_url[0]) {
            console.log("Payment URL found");
            window.location.href = response.payment_url[0];
            setIsOrdering(false); // Successfully redirected, stop the function
            return;
          } else {
            console.log("No URL returned, response:", response);
            if (attempt === maxRetries) {
              console.error("Failed to retrieve a valid payment URL after multiple attempts.");
              setIsOrdering(false);
            }
          }
      
        } catch (error) {
          console.log(`Attempt ${attempt} failed: Error creating order`, error);
      
          // Wait before retrying if it's not the last attempt
          if (attempt < maxRetries) {
            await new Promise(resolve => setTimeout(resolve, retryDelay));
          } else {
            console.log("All retry attempts failed.");
            setIsOrdering(false);
          }
        }
      }

      setIsOrdering(false);
  
      if (!response || !response.payment_url) {
        console.error("Order placed without a valid payment URL:", response);
        setStatusMessage("Er iets fout gegaan...");
      }      
  
    } catch (error) {
      console.log("Unexpected error in placeOrder:", error);
      setIsOrdering(false);
    }

  };
  

  return (
    <>

    {!isMobile && (
    <div className="desktopNavSpacer"></div>
     )}
       <div className="back">
        <Link
          className="hightlightsLink"
          to="/cart"
          style={{
            textDecoration: "none",
            display: "flex",
            alignItems: "center",
          }}
        >
          <img src={arrow} alt=""></img>
          <p>Terug</p>
        </Link>
      </div>
    <div id="detailsWrapper">
    <div id="detailsBox">
      <div className="detailsColumn">
        <CartBasic shippingCost={shippingCost} totalOrderPrice={totalOrderPrice}/>

        <h4>Bestelnotitie: (optioneel)</h4>
        <textarea
          placeholder="Opmerkingen over uw bestelling, zoals specifieke instructies voor de levering."
          required
          value={note}
          onChange={(e) => setNote(e.target.value)}
        ></textarea>
      </div>
      <div className="detailsColumn">
        {addressComplete === false ? (
          <div>
            <h2>Voer gegevens in:</h2>
            <form onSubmit={handleSubmitDetails}>
              <p>Contactgegevens</p>
              <br />
              <div className="nameWrapper">
              <input
                type="text"
                placeholder="Voornaam"
                required
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
              <input
                type="text"
                placeholder="Tussenvoegsel"
                value={preposition}
                onChange={(e) => setPreposition(e.target.value)}
              />
              <input
                type="text"
                placeholder="Achternaam"
                required
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
              </div>
              <input
                type="text"
                placeholder="Bedrijfsnaam"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
              />
              <input
                type="email"
                placeholder="E-mailadres"
                required
                value={emailAddress}
                onChange={(e) => setEmailAddress(e.target.value)}
              />
              <input
                type="tel"
                placeholder="Telefoonnummer"
                required
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
              <p>Adresgegevens</p>
              <br />
              <select
                required
                value={country}
                onChange={(e) => setCountry(e.target.value)}
              >
                <option value="" disabled>
                  Land
                </option>
                <option value="NL">Nederland</option>
                <option value="BE">België</option>
              </select>
              <input
                type="text"
                placeholder="Straatnaam"
                required
                value={streetName}
                onChange={(e) => setStreetName(e.target.value)}
              />
              <input
                type="text"
                placeholder="Huisnummer"
                required
                value={houseNumber}
                onChange={(e) => setHouseNumber(e.target.value)}
              />
              <input
                type="text"
                placeholder="Toevoeging huisnummer"
                value={houseNumberAddition}
                onChange={(e) => setHouseNumberAddition(e.target.value)}
              />
              <input
                type="text"
                placeholder="Postcode"
                required
                value={zipcode}
                onChange={(e) => setZipcode(e.target.value)}
              />
              <input
                type="text"
                placeholder="Plaats"
                required
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
                 <label>
                    <input type="checkbox"  
                    checked={differentShippingAddress}  // Attach the state variable
                    onChange={handleDifferentAddressCheckboxChange} />
                    Verzenden naar een ander adres?
                 </label>

                {differentShippingAddress && (

                <div className="differentAddressDiv">
                <p>Contactgegevens</p>
                <br />
                <div className="nameWrapper">
                <input
                  type="text"
                  placeholder="Voornaam"
                  required
                  value={shippingFirstName}
                  onChange={(e) => setShippingFirstName(e.target.value)}
                />
                <input
                  type="text"
                  placeholder="Tussenvoegsel"
                  value={shippingPreposition}
                  onChange={(e) => setShippingPreposition(e.target.value)}
                />
                <input
                  type="text"
                  placeholder="Achternaam"
                  required
                  value={shippingLastName}
                  onChange={(e) => setShippingLastName(e.target.value)}
                />
                </div>
                <input
                  type="text"
                  placeholder="Bedrijfsnaam"
                  value={shippingCompanyName}
                  onChange={(e) => setShippingCompanyName(e.target.value)}
                />
                <p>Adresgegevens</p>
                <br />
                <select
                  required
                  value={shippingCountry}
                  onChange={(e) => setShippingCountry(e.target.value)}
                >
                  <option value="" disabled>
                    Land
                  </option>
                  <option value="NL">Nederland</option>
                  <option value="BE">België</option>
                </select>
                <input
                  type="text"
                  placeholder="Straatnaam"
                  required
                  value={shippingStreetName}
                  onChange={(e) => setShippingStreetName(e.target.value)}
                />
                <input
                  type="text"
                  placeholder="Huisnummer"
                  required
                  value={shippingHouseNumber}
                  onChange={(e) => setShippingHouseNumber(e.target.value)}
                />
                <input
                  type="text"
                  placeholder="Toevoeging huisnummer"
                  value={shippingHouseNumberAddition}
                  onChange={(e) => setShippingHouseNumberAddition(e.target.value)}
                />
                <input
                  type="text"
                  placeholder="Postcode"
                  required
                  value={shippingZipcode}
                  onChange={(e) => setShippingZipcode(e.target.value)}
                />
                <input
                  type="text"
                  placeholder="Plaats"
                  required
                  value={shippingCity}
                  onChange={(e) => setShippingCity(e.target.value)}
                />
                </div>

                )}

              <button className='checkout-btn' type="submit">Bevestigen</button>
            </form>
          </div>
        ) : (
          <div id="orderSummary">
            {differentShippingAddress ? (
            <h2>Factuur gegevens:</h2>
            ) : (
            <h2>Bestelling gegevens:</h2>)}
            <h3>Contactgegevens:</h3>
            <div className="orderGrid">
            <p><strong>Voornaam:</strong></p><p>{firstName}</p>
            <p><strong>Tussenvoegsel:</strong></p><p>{preposition}</p>
            <p><strong>Achternaam:</strong></p><p>{lastName}</p>
            {companyName !== '' && <>
                <p><strong>Bedrijfsnaam:</strong></p><p>{companyName}</p>
            </>}
            <p><strong>Email:</strong></p><p>{emailAddress}</p>
            <p><strong>Telefoon:</strong></p><p>{phoneNumber}</p>
            </div>
            <h3>Adresgegevens:</h3>
            <div className="orderGrid">
                <p><strong>Straatnaam:</strong></p><p>{streetName}</p>
                <p><strong>Huisnummer:</strong></p><p>{houseNumber}</p>
                <p><strong>Toevoeging:</strong></p><p>{houseNumberAddition}</p>
                <p><strong>Postcode:</strong></p><p>{zipcode}</p>
                <p><strong>Plaats:</strong></p><p>{city}</p>
                <p><strong>Land:</strong></p><p>{country}</p>
            </div>

            {differentShippingAddress && (
              <div id="orderSummary">
                <h2>Verzend gegevens:</h2>
                
                <h3>Contactgegevens:</h3>
                <div className="orderGrid">
                  <p><strong>Voornaam:</strong></p><p>{shippingFirstName}</p>
                  <p><strong>Tussenvoegsel:</strong></p><p>{shippingPreposition}</p>
                  <p><strong>Achternaam:</strong></p><p>{shippingLastName}</p>
                  {shippingCompanyName && shippingCompanyName !== '' && (
                    <>
                      <p><strong>Bedrijfsnaam:</strong></p><p>{shippingCompanyName}</p>
                    </>
                  )}
                </div>
                
                <h3>Adresgegevens:</h3>
                <div className="orderGrid">
                  <p><strong>Straatnaam:</strong></p><p>{shippingStreetName}</p>
                  <p><strong>Huisnummer:</strong></p><p>{shippingHouseNumber}</p>
                  <p><strong>Toevoeging:</strong></p><p>{shippingHouseNumberAddition}</p>
                  <p><strong>Postcode:</strong></p><p>{shippingZipcode}</p>
                  <p><strong>Plaats:</strong></p><p>{shippingCity}</p>
                  <p><strong>Land:</strong></p><p>{shippingCountry}</p>
                </div>
              </div>
            )}

            <button className='checkout-btn' onClick={() => setAddressComplete(false)}>Wijzig</button>
          </div>
        )}
        <div id="payBox">
          <PaymentMethodSelector
            selectedMethod={selectedPaymentMethod}
            setSelectedMethod={setSelectedMethod}
          />
        </div>
        <div>
          <h2>Verzending:</h2>
          <ShippingOptions
            shippingOption={shippingOption}
            setShippingOption={setShippingOption}
            addressComplete={addressComplete}
            houseNumber={differentShippingAddress === false ? houseNumber : shippingHouseNumber}
            houseNumberAddition={differentShippingAddress === false ? houseNumberAddition : shippingHouseNumberAddition}
            streetName={differentShippingAddress === false ? streetName : shippingStreetName}
            city={differentShippingAddress === false ? city : shippingCity}
            zipCode={differentShippingAddress === false ? zipcode : shippingZipcode}
            country={differentShippingAddress === false ? country : shippingCountry}       
          />
        </div>      

        <div className="confirmWrapper">

        <p className="terms">Door op de knop "Plaats bestelling" te klikken, geeft u aan dat u ons privacybeleid 
          heeft gelezen en begrepen en hiermee akkoord gaat.</p>

          <label>
          <input 
            type="checkbox" 
            checked={confirmedAge} 
            onChange={handleAgeCheckboxChange} 
            required 
          />
          Ik verklaar hierbij dat ik 18 jaar of ouder ben op het moment van de aankoop.*
        </label>

        <button className="checkout-btn"  disabled={(!canPlaceOrder || isOrdering )} onClick={() => placeOrder()}>Plaats bestelling</button>
        <div id="orderLoadingBox">
        <ClipLoader color="#3498db" loading={isOrdering} size={15} />
        </div>
        <p id="orderStatusMessage">{statusMessage}</p>

        </div>
      </div>
    </div>
  </div>
  </>
  );
};

export default OrderDetails;
