import React from 'react';
import './styling/confirmModal.css';

const ConfirmModal = ({ show, message, onConfirm, onCancel }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="modal-overlay">
      <div className="modal">
        <h2>{message}</h2>
        <div className="modal-buttons">
          <button className="btn btn-pulci-green" onClick={onConfirm}>Bevestigen</button>
          <button className="btn btn-cancel" onClick={onCancel}>Annuleren</button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
