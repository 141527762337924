const BASE_URL = 'https://jouwklussenbedrijf.nl/dataservice';

export async function fetchCheckoutOptions(houseNumber, houseNumberAddition, streetName, city, zipCode, country) {
    console.log("Fetching checkout options...");
    try {
      const response = await fetch(`${BASE_URL}/get-checkout-options`, {
      //const response = await fetch(`http://localhost:5009/get-checkout-options`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          houseNumber,
          houseNumberAddition,
          streetName,
          city,
          zipCode,
          country
      }),
      });
  
      if (response.ok) {
        const shippingOptions = await response.json();
        return shippingOptions;
      } else {
        console.error('Failed to retrieve shipping options...');
        return false;
      }
    } catch (error) {
      console.error('Error retrieving shipping options:', error);
      return false;
    }
  }


  export async function fetchPickupOptions(houseNumber, houseNumberAddition, streetName, city, zipCode, country) {
    console.log("Fetching pickup options...");
    try {
      const response = await fetch(`${BASE_URL}/get-pickup-options`, {
      //const response = await fetch(`http://localhost:5009/get-pickup-options`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          houseNumber,
          houseNumberAddition,
          streetName,
          city,
          zipCode,
          country
      }),
      });
  
      if (response.ok) {
        const pickupOptions = await response.json();
        return pickupOptions;
      } else {
        console.error('Failed to retrieve pickup options...');
        return false;
      }
    } catch (error) {
      console.error('Error retrieving pickup options:', error);
      return false;
    }
  }


  export async function getLabelByOrderId(orderId) {
    try {
      //const response = await fetch(`${BASE_URL}/admin/label/${orderId}`);
      const response = await fetch(`http://localhost:5009/admin/label/${orderId}`);
      if (response.ok) {
        const label = await response.json();
        return label;
      } else {
        console.error('Failed to fetch PDF');
        return false;
      }
    } catch (error) {
      console.error('Error fetching PDF:', error);
      return false;
    }
  }