import React, { useState } from 'react';
import axios from 'axios';
import './styling/contactForm.css';

const ContactForm = () => {
    const [formData, setFormData] = useState({
      name: '',
      email: '',
      phone: '', 
      message: ''
    });
  
    const [statusMessage, setStatusMessage] = useState('');
  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value
      });
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
  
      axios.post('http://localhost:5009/send-contact-email', formData)
        .then((response) => {
          setStatusMessage('Bericht verzonden!');
          setFormData({
            name: '',
            email: '',
            phone: '',
            message: ''
          });
        })
        .catch((error) => {
          console.log(error);
          setStatusMessage('Er is iets fout gegaan met het verzenden...');
        });
    };
  
    return (
      <div id="contactFormWrapper">
        <h2>Contact Opnemen</h2>
  
        <p>Neem contact met ons op via het contactformulier en een van onze medewerkers zal z.s.m. contact met je opnemen.</p>
  
        <p>Reserveren in ons restaurant?</p>
        <a
          href="https://restaurant-pulcinos.lurch.app/reservation/"
          rel="noreferrer"
          target="_blank"
        >
          Klik dan hier
        </a>
  
        <form onSubmit={handleSubmit}>
          <div>
            <input
              type="text"
              name="name"
              placeholder='Wat is je naam?'
              value={formData.name}
              onChange={handleInputChange}
              required
            />
          </div>
          <div>
            <input
              type="email"
              name="email"
              placeholder='Wat is je email adres?'
              value={formData.email}
              onChange={handleInputChange}
              required
            />
          </div>
          <div>
            <input
              type="tel"
              name="phone"
              placeholder='Wat is je telefoonnummer?'
              value={formData.phone}
              onChange={handleInputChange}
              required
            />
          </div>
          <div>
            <textarea
              name="message"
              placeholder='Waar kunnen we je mee helpen?'
              value={formData.message}
              onChange={handleInputChange}
              required
            />
          </div>
          <button type="submit">Verzend Bericht</button>
          {/* {statusMessage && } */}
        </form>
        <p id="statusMessage">{statusMessage}</p>
      </div>
    );
  };
  
  export default ContactForm;